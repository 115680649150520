import * as UsersConstants from "../constants/users.constants";
import {Loading} from "../../utils/helpers/constants";
import {formatPermissions, formatRoles, formatUser} from "../../utils/helpers/logicHelper";

const initialState = {
  drivers: [],
  driver: {},
  customUsers: [],
  role: [],
  roles: [],
  permissions: [],
  tenantPermissions: [],
  userProfile: {},
  notifications: [],
  driverProfile: {},
  customUsersCount: "",
  driversCount: "",
  loadingDrivers: "",
  loadingDriver: "",
  loadingRole: "",
  loadingRoles: "",
  loadingWarehouseArrivals: "",
  loadingWarehouseArrivalInfo: "",
  loadingPermissions: "",
  loadingTenantPermissions: "",
  loadingCustomUsers: "",
  loadingUserProfile: "",
  loadingDriverProfile: "",
};

const usersReducer = (state = initialState, action) => {
  switch (action.type) {

    case UsersConstants.FETCH_DRIVERS_REQUEST:
      return {...state, loadingDrivers: Loading.FETCHING};

    case UsersConstants.FETCH_DRIVERS_SUCCESS:
      return {
        ...state, loadingDrivers: Loading.SUCCESS, drivers: action.payload.data, driversCount: action.payload.count
      }

    case UsersConstants.FETCH_DRIVERS_FAILURE:
      return {...state, loadingDrivers: Loading.ERROR};

    case UsersConstants.FETCH_DRIVER_REQUEST:
      return {...state, loadingDriver: Loading.FETCHING};

    case UsersConstants.FETCH_DRIVER_SUCCESS:
      return {...state, loadingDriver: Loading.SUCCESS, driver: action.payload.data}

    case UsersConstants.FETCH_DRIVER_FAILURE:
      return {...state, loadingDriver: Loading.ERROR};
    
    case UsersConstants.FETCH_CUSTOM_USERS_REQUEST:
      return {...state, loadingCustomUsers: Loading.FETCHING};

    case UsersConstants.FETCH_CUSTOM_USERS_SUCCESS:
      return {
        ...state, loadingCustomUsers: Loading.SUCCESS,
        customUsers: action.payload.data, customUsersCount: action.payload.count
      };

    case UsersConstants.FETCH_CUSTOM_USERS_FAILURE:
      return {...state, loadingCustomUsers: Loading.ERROR};

    case UsersConstants.FETCH_USER_ROLES_REQUEST:
      return {...state, loadingRoles: Loading.FETCHING};

    case UsersConstants.FETCH_USER_ROLES_SUCCESS:
      const roles = formatRoles(action.payload);
      return {...state, loadingRoles: Loading.SUCCESS, roles};

    case UsersConstants.FETCH_USER_ROLES_FAILURE:
      return {...state, loadingRoles: Loading.ERROR};

    case UsersConstants.FETCH_USER_ROLE_REQUEST:
      return {...state, loadingRole: Loading.FETCHING};

    case UsersConstants.FETCH_USER_ROLE_SUCCESS:
      return {...state, loadingRole: Loading.SUCCESS, role: action.payload};

    case UsersConstants.FETCH_USER_ROLE_FAILURE:
      return {...state, loadingRole: Loading.ERROR};

    case UsersConstants.FETCH_USER_PERMISSIONS_REQUEST:
      return {...state, loadingPermissions: Loading.FETCHING};

    case UsersConstants.FETCH_USER_PERMISSIONS_SUCCESS:
      return {...state, loadingPermissions: Loading.SUCCESS, permissions: action.payload};

    case UsersConstants.FETCH_USER_PERMISSIONS_FAILURE:
      return {...state, loadingPermissions: Loading.ERROR};

    case UsersConstants.FETCH_TENANT_PERMISSIONS_REQUEST:
      return {...state, loadingTenantPermissions: Loading.FETCHING};

    case UsersConstants.FETCH_TENANT_PERMISSIONS_SUCCESS:
      const tenantPermissions = formatPermissions(action.payload);
      return {...state, loadingTenantPermissions: Loading.SUCCESS, tenantPermissions};

    case UsersConstants.FETCH_TENANT_PERMISSIONS_FAILURE:
      return {...state, loadingTenantPermissions: Loading.ERROR};

    case UsersConstants.FETCH_USER_PROFILE_REQUEST:
      return {...state, loadingUserProfile: Loading.FETCHING};

    case UsersConstants.FETCH_USER_PROFILE_SUCCESS:
      let userProfile = action.payload.length < 1 ? {} : action.payload[0];
      return {...state, loadingUserProfile: Loading.SUCCESS, userProfile};

    case UsersConstants.FETCH_USER_PROFILE_FAILURE:
      return {...state, loadingUserProfile: Loading.ERROR};

    case UsersConstants.FETCH_DRIVER_ORGANISATION_PROFILE_REQUEST:
      return {...state, loadingDriverProfile: Loading.FETCHING};

    case UsersConstants.FETCH_DRIVER_ORGANISATION_PROFILE_SUCCESS:
      let driverProfile = action.payload.length < 1 ? {} : action.payload[0];
      return {...state, loadingDriverProfile: Loading.SUCCESS, driverProfile};

    case UsersConstants.FETCH_DRIVER_ORGANISATION_PROFILE_FAILURE:
      return {...state, loadingDriverProfile: Loading.ERROR};

    case 'RESET_STATE':
      return initialState;

    default:
      return state;
  }
};

export default usersReducer;
