import {dodgeInstance as Axios} from './axios.service';

const AnalyticsService = {

  getOverview(suffix) {
    return Axios({
      method: 'GET',
      url: `/locale-service/analytics/overview/${suffix}`,
    });
  },

  getComposite() {
    return Axios({
      method: 'GET',
      url: `/locale-service/analytics/insights/composite`,
    });
  },

  getDatastoreInsights(payload) {
    return Axios({
      method: 'POST',
      url: `/locale-service/analytics/insights`,
      data: payload
    });
  },


  getOnboardingStatus(reference) {
    return Axios({
      method: 'GET',
      url: `/locale-service/analytics/onboarding/status`,
      headers: {organisation: reference},
    });
  },

  updateOnboardingMetrics(url, method, payload) {
    return Axios({
      method: method,
      url: url,
      data: payload
    });
  },

  getExportData(payload, suffix) {
    return Axios({
      method: 'POST',
      url: `/locale-service/analytics/insights/${suffix}`,
      data: payload,
    });
  },
};

export default AnalyticsService;
