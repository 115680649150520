import {Fragment, useState} from 'react';
import Modal from 'react-bootstrap/Modal';

const NetworkModal = () => {
  const [show, setShow] = useState(true);
  const handleClose = () => setShow(false);

  return (
    <Fragment>
      <Modal show={show} backdrop="static" centered keyboard={false}>
        <Modal.Body>
          <button aria-label="Close" onClick={handleClose} className="btn-close float-end"
                  data-bs-dismiss="modal"/>

          <div className="modal-body text-center p-3">
            <i className="fa fa-exclamation-circle fs-50 text-danger mb-4 d-inline-block"/>
            <h4 className="text-danger mb-20">Seems like your internet may be down!</h4>
            <p className="mb-4 mx-4">No network connection. Check your connection..</p>
            <button aria-label="Close" onClick={handleClose} className="btn btn-danger">Continue Anyway</button>
          </div>
        </Modal.Body>
      </Modal>
    </Fragment>
  );
}

export default NetworkModal;
