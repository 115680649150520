import { AppConfigs, UserType } from './constants';
import { setKey } from './logicHelper';
import cookieHelper from "./cookieHelper";

const TokenHelper = {

	checkIfLoggedIn() {
		const TOKEN = cookieHelper.get(AppConfigs.KEY);
		return !!TOKEN;
	},

	saveToken(token) {
		cookieHelper.set(AppConfigs.KEY, token, AppConfigs.COOKIE_EXPIRY_PERIOD);
		localStorage.setItem(setKey(AppConfigs.KEY), token);
	},

	getToken() {
		return cookieHelper.get(AppConfigs.KEY);
	},

	saveUserType() {
		cookieHelper.set(AppConfigs.USER_TYPE, UserType.TENANT, AppConfigs.COOKIE_EXPIRY_PERIOD);
	},

	getUserType() {
		return cookieHelper.get(AppConfigs.USER_TYPE);
	},

	saveSelectedOrg(data) {
		cookieHelper.set(AppConfigs.USER_ORG, data, AppConfigs.COOKIE_EXPIRY_PERIOD);
	},

	getSelectedOrg() {
		return cookieHelper.get(AppConfigs.USER_ORG);
	},

	saveSelectedOrgCountry(data) {
		cookieHelper.set(AppConfigs.ORG_COUNTRY, data, AppConfigs.COOKIE_EXPIRY_PERIOD);
	},

	getSelectedOrgCountry() {
		return cookieHelper.get(AppConfigs.ORG_COUNTRY);
	},

	saveUserRole(userRole) {
		cookieHelper.set(AppConfigs.USER_ROLE, userRole, AppConfigs.COOKIE_EXPIRY_PERIOD);
	},

	getUserRole() {
		return cookieHelper.get(AppConfigs.USER_ROLE);
	},

	saveOnboardingStatus(status) {
		cookieHelper.set(AppConfigs.ONBOARDING_KEY, status, AppConfigs.COOKIE_EXPIRY_PERIOD);
	},

	getOnboardingStatus() {
		return cookieHelper.get(AppConfigs.ONBOARDING_KEY);
	},

	saveOnboardingIndex(index) {
		cookieHelper.set(AppConfigs.ONBOARDING_INDEX, index, AppConfigs.COOKIE_EXPIRY_PERIOD);
	},

	getOnboardingIndex() {
		return cookieHelper.get(AppConfigs.ONBOARDING_INDEX);
	},

}

export default TokenHelper;
