export const FETCH_DRIVERS_REQUEST = "FETCH_DRIVERS_REQUEST";
export const FETCH_DRIVERS_SUCCESS = "FETCH_DRIVERS_SUCCESS";
export const FETCH_DRIVERS_FAILURE = "FETCH_DRIVERS_FAILURE";

export const FETCH_DRIVER_REQUEST = "FETCH_DRIVER_REQUEST";
export const FETCH_DRIVER_SUCCESS = "FETCH_DRIVER_SUCCESS";
export const FETCH_DRIVER_FAILURE = "FETCH_DRIVER_FAILURE";

export const FETCH_CUSTOM_USERS_REQUEST = "FETCH_CUSTOM_USERS_REQUEST";
export const FETCH_CUSTOM_USERS_SUCCESS = "FETCH_CUSTOM_USERS_SUCCESS";
export const FETCH_CUSTOM_USERS_FAILURE = "FETCH_CUSTOM_USERS_FAILURE";

export const FETCH_USER_PROFILE_REQUEST = "FETCH_USER_PROFILE_REQUEST";
export const FETCH_USER_PROFILE_SUCCESS = "FETCH_USER_PROFILE_SUCCESS";
export const FETCH_USER_PROFILE_FAILURE = "FETCH_USER_PROFILE_FAILURE";

export const FETCH_USER_ROLES_REQUEST = "FETCH_USER_ROLES_REQUEST";
export const FETCH_USER_ROLES_SUCCESS = "FETCH_USER_ROLES_SUCCESS";
export const FETCH_USER_ROLES_FAILURE = "FETCH_USER_ROLES_FAILURE";

export const FETCH_USER_ROLE_REQUEST = "FETCH_USER_ROLE_REQUEST";
export const FETCH_USER_ROLE_SUCCESS = "FETCH_USER_ROLE_SUCCESS";
export const FETCH_USER_ROLE_FAILURE = "FETCH_USER_ROLE_FAILURE";

export const FETCH_USER_PERMISSIONS_REQUEST = "FETCH_USER_PERMISSIONS_REQUEST";
export const FETCH_USER_PERMISSIONS_SUCCESS = "FETCH_USER_PERMISSIONS_SUCCESS";
export const FETCH_USER_PERMISSIONS_FAILURE = "FETCH_USER_PERMISSIONS_FAILURE";

export const FETCH_TENANT_PERMISSIONS_REQUEST = "FETCH_TENANT_PERMISSIONS_REQUEST";
export const FETCH_TENANT_PERMISSIONS_SUCCESS = "FETCH_TENANT_PERMISSIONS_SUCCESS";
export const FETCH_TENANT_PERMISSIONS_FAILURE = "FETCH_TENANT_PERMISSIONS_FAILURE";

export const FETCH_DRIVER_ORGANISATION_PROFILE_REQUEST = "FETCH_DRIVER_ORGANISATION_PROFILE_REQUEST";
export const FETCH_DRIVER_ORGANISATION_PROFILE_SUCCESS = "FETCH_DRIVER_ORGANISATION_PROFILE_SUCCESS";
export const FETCH_DRIVER_ORGANISATION_PROFILE_FAILURE = "FETCH_DRIVER_ORGANISATION_PROFILE_FAILURE";
