import { BrowserRouter as Router, Navigate, Outlet, Route, Routes } from "react-router-dom";
import { Suspense, lazy } from "react";
import { ToastContainer } from "react-toastify";

import TokenHelpers from "./utils/helpers/tokenHelper";
import SphereLoader from "./components/loaders/SphereLoader";
import { MenuType, SubMenuType } from "./utils/helpers/constants";
import ErrorBoundaryHook from "./components/hooks/ErrorBoundaryHook";

/***************************************************************************
ROUTE-BASED CODE SPLITTING: 
1) https://www.syncfusion.com/blogs/post/lazy-loading-with-react/amp
2) https://legacy.reactjs.org/docs/code-splitting.html
***************************************************************************/
const Login = lazy(() => import("./pages/authentication/Login"));
const ResetPassword = lazy(() => import("./pages/authentication/ResetPassword"));
const ForgotPassword = lazy(() => import("./pages/authentication/ForgotPassword"));
const RegisterTeamMember = lazy(() => import("./pages/authentication/RegisterTeamMember"));
const RegisterOrganisation = lazy(() => import("./pages/authentication/RegisterOrganisation"));

const Home = lazy(() => import("./pages/dashboard/home/Home"));
const AuditTrail = lazy(() => import("./pages/dashboard/auditTrail/AuditTrail"));
const Overview = lazy(() => import("./pages/dashboard/services/overview/Overview"));
const Integrations = lazy(() => import("./pages/dashboard/integrations/Integrations"));
const Organisations = lazy(() => import("./pages/dashboard/organisations/Organisations"));
const MarketPlace = lazy(() => import("./pages/dashboard/services/marketPlace/MarketPlace"));
const NonOrganisation = lazy(() => import("./pages/dashboard/home/nonOrganisation/NonOrganisation"));
const OrganisationInfo = lazy(() => import("./pages/dashboard/organisations/atom/OrganisationInfo"));
const OnboardOrganisation = lazy(() => import("./pages/dashboard/home/onboardOrganisation/OnboardOrganisation"));

const UserConfigs = lazy(() => import("./pages/dashboard/services/entity/usersManagement/userConfiguration/UserConfigs"));
const UsersManagement = lazy(() => import("./pages/dashboard/services/entity/usersManagement/management/UsersManagement"));
const BusinessInfo = lazy(() => import("./pages/dashboard/services/entity/businessManagement/management/atom/BusinessInfo"));
const BusinessManagement = lazy(() => import("./pages/dashboard/services/entity/businessManagement/management/BusinessManagement"));
const BusinessPricingModelConfigs = lazy(() => import("./pages/dashboard/services/entity/businessManagement/businessPricingConfiguration/BusinessPricingModelConfigs"));

const QuotesLeads = lazy(() => import("./pages/dashboard/services/entity/bookingManagement/quotesLeads/QuotesLeads"));
const TripManagement = lazy(() => import("./pages/dashboard/services/entity/bookingManagement/management/TripManagement"));
const TripInfo = lazy(() => import("./pages/dashboard/services/entity/bookingManagement/management/atom/tripInfo/tripInfo"));
const TripPoolConfigs = lazy(() => import("./pages/dashboard/services/entity/bookingManagement/tripPoolConfiguration/TripPoolConfigs"));
const TripPoolQuotes = lazy(() => import("./pages/dashboard/services/entity/bookingManagement/management/atom/tripPool/TripPoolQuotes"));
const ValueAddedServices = lazy(() => import("./pages/dashboard/services/entity/bookingManagement/valueAddedServices/ValueAddedServices"));
const PricingModelConfigs = lazy(() => import("./pages/dashboard/services/entity/bookingManagement/pricingConfiguration/PricingModelConfigs"));

const VehicleInfo = lazy(() => import("./pages/dashboard/services/entity/fleetManagement/management/atom/VehicleInfo"));
const FleetManagement = lazy(() => import("./pages/dashboard/services/entity/fleetManagement/management/FleetManagement"));
const FleetConfigs = lazy(() => import("./pages/dashboard/services/entity/fleetManagement/fleetConfiguration/FleetConfigs"));
const VehicleCategories = lazy(() => import("./pages/dashboard/services/entity/fleetManagement/categories/VehicleCategories"));
const DriverInfo = lazy(() => import("./pages/dashboard/services/entity/driversManagement/management/atom/DriverInfo"));
const DriversManagement = lazy(() => import("./pages/dashboard/services/entity/driversManagement/management/DriversManagement"));
const DriverConfigs = lazy(() => import("./pages/dashboard/services/entity/driversManagement/driverConfiguration/DriverConfigs"));
const BookingConfigs = lazy(() => import("./pages/dashboard/services/entity/bookingManagement/bookingConfiguration/BookingConfigs"));

const Products = lazy(() => import("./pages/dashboard/services/entity/warehouseAutomation/products/Products"));
const OrderSets = lazy(() => import("./pages/dashboard/services/entity/warehouseAutomation/ordersets/OrderSets"));
const Categories = lazy(() => import("./pages/dashboard/services/entity/warehouseAutomation/categories/Categories"));
const Warehouses = lazy(() => import("./pages/dashboard/services/entity/warehouseAutomation/warehouses/Warehouses"));
const ProductInfo = lazy(() => import("./pages/dashboard/services/entity/warehouseAutomation/products/atom/ProductInfo"));
const WarehouseInfo = lazy(() => import("./pages/dashboard/services/entity/warehouseAutomation/warehouses/atom/WarehouseInfo"));
const WarehouseArrivals = lazy(() => import("./pages/dashboard/services/entity/warehouseAutomation/arrivals/WarehouseArrivals"));
const CreateManifest = lazy(() => import("./pages/dashboard/services/entity/warehouseAutomation/ordersets/atom/CreateManifest"));
const OrderSetInfo = lazy(() => import("./pages/dashboard/services/entity/warehouseAutomation/ordersets/atom/ordersetInfo/OrderSetInfo"));
const WarehouseArrivalInfo = lazy(() => import("./pages/dashboard/services/entity/warehouseAutomation/arrivals/atom/WarehouseArrivalInfo"));

const Team = lazy(() => import("./pages/dashboard/iam/team/Team"));
const Roles = lazy(() => import("./pages/dashboard/iam/roles/Roles"));
const Profile = lazy(() => import("./pages/dashboard/iam/profile/Profile"));
const Billings = lazy(() => import("./pages/dashboard/iam/billings/Billings"));
const Permissions = lazy(() => import("./pages/dashboard/iam/permissions/Permissions"));

const UserWallet = lazy(() => import("./pages/dashboard/finance/wallets/userWallet/UserWallet"));
const WithdrawalEntries = lazy(() => import("./pages/dashboard/finance/withdrawals/WithdrawalEntries"));
const AdvancedPayments = lazy(() => import("./pages/dashboard/finance/advancePayments/AdvancePayments"));
const OrganisationWallet = lazy(() => import("./pages/dashboard/finance/wallets/organisationWallet/OrganisationWallet"));

const Insights = lazy(() => import("./pages/dashboard/analytics/insights/Insights"));
const FinancialReports = lazy(() => import("./pages/dashboard/finance/reports/FinancialReports"));
const FinancialConfigs = lazy(() => import("./pages/dashboard/finance/configs/FinancialConfigs"));
const TripInsights = lazy(() => import("./pages/dashboard/analytics/insights/atom/TripInsights"));
const BusinessInsights = lazy(() => import("./pages/dashboard/analytics/insights/atom/BusinessInsights"));
const DeliveryCollections = lazy(() => import("./pages/dashboard/finance/deliveryCollections/DeliveryCollections"));

const Error403 = lazy(() => import("./pages/misc/error/Error403"));
const Error404 = lazy(() => import("./pages/misc/error/Error404"));


const App = () => {

  const AuthenticatedRoutes = () => {
    if (TokenHelpers.checkIfLoggedIn()) return <Outlet />
    else return <Navigate to={`/${MenuType.AUTH}/${SubMenuType.LOGIN}`} replace />;
  };

  const OnboardedRoutes = () => {
    let onboardingStatus = TokenHelpers.getOnboardingStatus();
    if (onboardingStatus === 'ONBOARDED') return <Outlet />;
    else if (onboardingStatus === 'NON_ORGANISATION') return <Navigate to={`/${SubMenuType.ONBOARD_ORGANISATION}`} replace />;
    else return <Navigate to={`/${SubMenuType.ONBOARDING_PROFILE}`} replace />
  };

  return (
    <Router>
      <ToastContainer position="top-right" theme="light" autoClose={7000} style={{ zIndex: '99999999999' }}
        hideProgressBar={false} newestOnTop={false} rtl={false} closeOnClick={false}
        pauseOnFocusLoss draggable pauseOnHover />
      {/* <ErrorBoundaryHook> */}
      <Suspense fallback={<SphereLoader />}>
        <Routes>
          <Route path={MenuType.AUTH}>
            <Route path={SubMenuType.LOGIN} element={<Login />} />
            <Route path={SubMenuType.RESET_PASSWORD} element={<ResetPassword />} />
            <Route path={SubMenuType.REGISTER} element={<RegisterOrganisation />} />
            <Route path={SubMenuType.FORGOT_PASSWORD} element={<ForgotPassword />} />
            <Route path={SubMenuType.INVITE_USER} element={<RegisterTeamMember />} />
          </Route>

          <Route element={<AuthenticatedRoutes />}>

            <Route path={SubMenuType.ONBOARDING_PROFILE} element={<OnboardOrganisation />} />
            <Route path={`/${SubMenuType.ONBOARD_ORGANISATION}`} element={<NonOrganisation />} />

            <Route element={<OnboardedRoutes />}>
              {['/', 'home', 'dashboard'].map(path => <Route path={path} key={path} element={<Home />} />)}
              <Route path="api-integration" element={<Integrations />} />

              <Route path={MenuType.AUDIT_TRAIL} element={<AuditTrail />} />

              <Route path={MenuType.INSIGHTS}>
                <Route index element={<Insights />} />
                <Route path="trips" element={<TripInsights />} />
                <Route path="business/:ref" element={<BusinessInsights />} />
              </Route>

              <Route path={MenuType.FINANCES} element={<Outlet />}>
                <Route path={SubMenuType.ADVANCED_PAYMENTS} element={<AdvancedPayments />} />
                <Route path={SubMenuType.WALLET_WITHDRAWALS} element={<WithdrawalEntries />} />
                <Route path={SubMenuType.DELIVERY_COLLECTIONS} element={<DeliveryCollections />} />
                <Route path={SubMenuType.CONFIGS} element={<FinancialConfigs />} />
                <Route path={SubMenuType.REPORTS} element={<FinancialReports />} />
                <Route path={SubMenuType.WALLETS}>
                  {[`/${MenuType.FINANCES}/${SubMenuType.WALLETS}`, `/${MenuType.FINANCES}/${SubMenuType.WALLETS}/:ref`].map(path => <Route index path={path} key={path} element={<OrganisationWallet />} />)}
                  <Route path=":ref/:email" element={<UserWallet />} />
                </Route>
              </Route>

              <Route path="/organisations">
                <Route index element={<Organisations />} />
                <Route path=":ref" element={<OrganisationInfo />} />
              </Route>

              <Route path={MenuType.SERVICES}>
                <Route path="overview/:ref" element={<Overview />} />
                <Route path="user-management/profile" element={<UsersManagement />} />
                <Route path="user-management/configs" element={<UserConfigs />} />
                <Route path="business-management/profile" element={<BusinessManagement />} />
                <Route path="business-management/profile/:id" element={<BusinessInfo />} />
                <Route path="business-management/profile/:id/:config" element={<BusinessPricingModelConfigs />} />
                <Route path="driver-management/profile" element={<DriversManagement />} />
                <Route path="driver-management/configs" element={<DriverConfigs />} />
                <Route path="driver-management/profile/:id" element={<DriverInfo />} />
                <Route path="fleet-management/fleet" element={<FleetManagement />} />
                <Route path="fleet-management/configs" element={<FleetConfigs />} />
                <Route path="fleet-management/fleet/:id" element={<VehicleInfo />} />
                <Route path="trip-management/trips" element={<TripManagement />} />
                <Route path="trip-management/trips-quote-leads" element={<QuotesLeads />} />
                <Route path="trip-management/trips/:ref" element={<TripInfo />} />
                <Route path="trip-management/trips/:ref/:interoperabilityRef" element={<TripPoolQuotes />} />
                <Route path="trip-management/categories" element={<VehicleCategories />} />
                <Route path="trip-management/configs" element={<BookingConfigs />} />
                <Route path="trip-management/value-added-services" element={<ValueAddedServices />} />
                <Route path="trip-management/pricing-configs" element={<PricingModelConfigs />} />
                <Route path="trip-management/open-trip-configs" element={<TripPoolConfigs />} />
                <Route path={`${SubMenuType.WAREHOUSE}/arrivals`} element={<WarehouseArrivals />} />
                <Route path={`${SubMenuType.WAREHOUSE}/arrivals/:id`} element={<WarehouseArrivalInfo />} />
                <Route path={`${SubMenuType.WAREHOUSE}/warehouses`} element={<Warehouses />} />
                <Route path={`${SubMenuType.WAREHOUSE}/warehouses/:id`} element={<WarehouseInfo />} />
                <Route path={`${SubMenuType.WAREHOUSE}/products`} element={<Products />} />
                <Route path={`${SubMenuType.WAREHOUSE}/products/:id`} element={<ProductInfo />} />
                <Route path={`${SubMenuType.WAREHOUSE}/products/categories`} element={<Categories />} />
                <Route path={`${SubMenuType.WAREHOUSE}/deliveries`} element={<OrderSets />} />
                <Route path={`${SubMenuType.WAREHOUSE}/deliveries/:ref`} element={<OrderSetInfo />} />
                <Route path={`${SubMenuType.WAREHOUSE}/deliveries/create-trip`} element={<CreateManifest />} />
                <Route path={MenuType.MARKETPLACE} element={<MarketPlace />} />
              </Route>

              <Route path={"/iam"}>
                <Route path="team-members" element={<Team />} />
                {/* <Route path="billings" element={<Billings />} /> */}
                <Route path="roles" element={<Roles />} />
                <Route path="permissions" element={<Permissions />} />
                <Route path="profile" element={<Profile />} />
              </Route>
            </Route>
          </Route>
          <Route path="/403" element={<Error403 />} />
          <Route path="*" element={<Error404 />} />
        </Routes>
      </Suspense>
      {/* </ErrorBoundaryHook> */}
    </Router>
  );
}

export default App;