import { dodgeInstance as Axios } from './axios.service';
import { UserType } from "../helpers/constants";

const ProfileService = {
	getProfileVerificationMail(callback) {
		return Axios({
			method: 'GET',
			url: `locale-service/tenants/profile-verification?callbackUrl=${callback}`,
		});
	},

	updateProfileVerificationStatus(payload) {
		return Axios({
			method: 'PATCH',
			url: `locale-service/tenants/verify-profile`,
			data: payload
		});
	},

	changeProfilePicture(payload) {
		return Axios({
			method: 'PATCH',
			url: `locale-service/tenants/profile-picture`,
			data: payload
		});
	},

	organisationImage(payload) {
		return Axios({
			method: 'PATCH',
			url: `locale-service/tenants/organisation-media`,
			data: payload,
		});
	},

	changePassword(payload) {
		return Axios({
			method: 'PATCH',
			url: `locale-service/tenants/change-password/`,
			data: payload
		});
	},

	registerTenant(payload) {
		return Axios({
			method: 'POST',
			url: `locale-service/tenants/register`,
			data: payload,
		});
	},

	registerTeamMember(payload) {
		return Axios({
			method: 'POST',
			url: `locale-service/tenants/add-user`,
			data: payload,
		});
	},

	inviteUserToOrganisation(payload) {
		return Axios({
			method: 'POST',
			url: `locale-service/tenants/invite-user`,
			data: payload,
		});
	},

	acceptInviteRequest(payload, reference) {
		return Axios({
			method: 'PATCH',
			url: `locale-service/tenants/join-organisation`,
			data: payload,
			headers: { organisation: reference }
		});
	},

	removeTeamMember(payload) {
		return Axios({
			method: 'PATCH',
			url: `locale-service/tenants/remove-member`,
			data: payload,
		});
	},

	changeTeamMemberRole(payload) {
		return Axios({
			method: 'PATCH',
			url: `locale-service/tenants/change-role`,
			data: payload,
		});
	},

	getProfile() {
		return Axios({
			method: 'GET',
			url: `locale-service/tenants/profile`,
			headers: { platform: UserType.TENANT }
		});
	},

	updateProfile(platform, payload) {
		return Axios({
			method: 'PATCH',
			url: `locale-service/tenants/edit-profile`,
			data: payload,
			headers: { platform: platform }
		})
	},

	/**************************** ORGANISATION ***********************************/
	createDomain(payload) {
		return Axios({
			method: 'PATCH',
			url: `locale-service/tenants/change-domain`,
			data: payload,
		});
	},

	assignManagerToOrg(payload) {
		return Axios({
			method: 'PATCH',
			url: `locale-service/tenants/reassign-manager`,
			data: payload,
		});
	},

	createOrganisation(payload) {
		return Axios({
			method: 'POST',
			url: `locale-service/tenants/add-organisation`,
			data: payload,
			headers: { organisation: 'NEW' }
		});
	},

	getOrganisations(filter) {
		return Axios({
			method: 'GET',
			url: `locale-service/tenants/organisations/${filter}`
		});
	},

	getOrganisation(ref) {
		return Axios({
			method: 'GET',
			url: `locale-service/tenants/organisation/${ref}`
		});
	},


	updateOrganisation(reference, payload) {
		return Axios({
			method: 'PATCH',
			url: `locale-service/tenants/edit-organisation/${reference}`,
			data: payload
		});
	},

	deleteOrganisation(reference) {
		return Axios({
			method: 'DELETE',
			url: `locale-service/tenants/delete-organisation/${reference}`,
		});
	},

	favouriteOrganisation(id) {
		return Axios({
			method: 'GET',
			url: `locale-service/tenants/organisation/favourite/${id}`,
		});
	},

	getOrganisationMembers(ref) {
		return Axios({
			method: 'GET',
			url: `locale-service/tenants/organisation-members/${ref}`,
		});
	},

	/*********************************** BUSINESS ***********************************/
	createBusiness(payload) {
		return Axios({
			method: 'POST',
			url: `entity-service/business/business-profile/`,
			data: payload
		});
	},

	updateBusiness(id, payload) {
		return Axios({
			method: 'PATCH',
			url: `entity-service/business/business-profile/${id}/`,
			data: payload
		});
	},

	toggleBusinessType(id, payload) {
		return Axios({
			method: 'POST',
			url: `entity-service/business/business-profile/${id}/switch_business_type/`,
			data: payload
		});
	},

	getBusinesses(filter) {
		return Axios({
			method: 'GET',
			url: `entity-service/business/business-profile/${filter}`,
		});
	},

	searchForBusiness(payload) {
		return Axios({
			method: 'POST',
			url: `entity-service/business/business-profile/search/`,
			data: payload
		});
	},

	transferBusiness(id, payload) {
		return Axios({
			method: 'POST',
			url: `entity-service/business/business-profile/${id}/transfer_business/`,
			data: payload,
		});
	},

	updateDriverBusinessAssign(payload, suffix) {
		return Axios({
			method: 'POST',
			url: `entity-service/business/business-profile/${suffix}/`,
			data: payload
		})
	},

	toggleBusinessStatus(id, type) {
		return Axios({
			method: 'GET',
			url: `entity-service/business/business-profile/${id}/${type}/`,
		});
	},

	toggleMerchantPriceConfigType(id, payload) {
		return Axios({
			method: 'POST',
			url: `entity-service/business/business-profile/${id}/update_merchant_configuration/`,
			data: payload
		});
	},

	togglePaymentConfiguration(id, payload) {
		return Axios({
			method: 'POST',
			url: `entity-service/business/business-profile/${id}/switch_pricing_config/`,
			data: payload,
		});
	},

	toggleActivateBusinessCategory(id, businessRef) {
		return Axios({
			method: 'GET',
			url: `entity-service/business/business-pricing-config/${id}/toggle_pricing_is_active/`,
			headers: { entity: businessRef }
		})
	},

	updateBusinessCommissionType(id, payload) {
		return Axios({
			method: 'POST',
			url: `entity-service/business/business-profile/${id}/update_commission_type/`,
			data: payload,
		});
	},

	updateBusinessVATType(id, payload) {
		return Axios({
			method: 'POST',
			url: `entity-service/business/business-profile/${id}/update_vat_type/`,
			data: payload,
		});
	},

	updateBusinessFulfilmentType(id, payload) {
		return Axios({
			method: 'POST',
			url: `entity-service/business/business-profile/${id}/update_fulfilment_option/`,
			data: payload,
		});
	},

	getPaymentConfiguration(businessRef) {
		return Axios({
			method: 'GET',
			url: `entity-service/business/business-pricing-config/`,
			headers: { entity: businessRef }
		});
	},

	createBusinessPricing(businessRef, payload) {
		return Axios({
			method: 'POST',
			url: `entity-service/business/business-pricing-config/`,
			data: payload,
			headers: { entity: businessRef }
		});
	},

	mutateMerchantCoupon(id, payload) {
		return Axios({
			method: 'POST',
			url: `booking-service/booking/booking-organisation-added-services/${id}/configure_merchant_coupon/`,
			data: payload,
		});
	},

	editBusinessPricing(id, businessRef, payload) {
		return Axios({
			method: 'PATCH',
			url: `entity-service/business/business-pricing-config/${id}/`,
			data: payload,
			headers: { entity: businessRef }
		});
	},
	deleteBusinessPricing(id, businessRef) {
		return Axios({
			method: 'DELETE',
			url: `entity-service/business/business-pricing-config/${id}/`,
			headers: { entity: businessRef }
		});
	},

	businessCommission(id, businessRef, payload) {
		return Axios({
			method: 'POST',
			url: `entity-service/business/business-pricing-config/${id}/set_pricing_commission/`,
			data: payload,
			headers: { entity: businessRef }
		});
	},


	/**************************** WAREHOUSE ****************************/
	getWarehouses(filter) {
		return Axios({
			method: 'GET',
			url: `warehouse-service/warehouse/${filter}`
		})
	},

	getTenancyWarehouses(filter) {
		return Axios({
			method: 'GET',
			url: `warehouse-service/warehouse/organisation/${filter}`
		})
	},

	createWarehouse(payload) {
		return Axios({
			method: 'POST',
			url: `warehouse-service/warehouse/create-warehouse`,
			data: payload,
		});
	},

	updateWarehouse(id, payload) {
		return Axios({
			method: 'PATCH',
			url: `warehouse-service/warehouse/update/${id}`,
			data: payload
		})
	},

	updateWarehouseManager(id, payload) {
		return Axios({
			method: 'PATCH',
			url: `warehouse-service/warehouse/update-administrative/${id}`,
			data: payload
		})
	},

	deleteWarehouse(id) {
		return Axios({
			method: 'DELETE',
			url: `warehouse-service/warehouse/delete/${id}`,
		})
	},

	getWarehouseCatalogues(warehouseRef, filter) {
		return Axios({
			method: 'GET',
			url: `warehouse-service/inventory/catalogue/warehouse/${warehouseRef}/${filter}`
		})
	},

	getWarehouseDefects(warehouseRef, filter) {
		return Axios({
			method: 'GET',
			url: `warehouse-service/inventory/catalogue/warehouse/defects/${warehouseRef}/${filter}`
		})
	},

	searchForCatalogue(payload) {
		return Axios({
			method: 'POST',
			url: `warehouse-service/inventory/catalogue/search`,
			data: payload
		})
	},

	createCatalogue(payload) {
		return Axios({
			method: 'POST',
			url: `warehouse-service/inventory/catalogue/create`,
			data: payload
		})
	},

	updateCatalogue(catalogueRef, payload) {
		return Axios({
			method: 'PATCH',
			url: `warehouse-service/inventory/catalogue/update/${catalogueRef}`,
			data: payload
		})
	},

	updateCatalogueExpiry(catalogueRef, payload) {
		return Axios({
			method: 'PATCH',
			url: `warehouse-service/inventory/catalogue/update-expiry-date/${catalogueRef}`,
			data: payload
		})
	},

	updateCatalogueDefects(catalogueRef, payload) {
		return Axios({
			method: 'PATCH',
			url: `warehouse-service/inventory/catalogue/update-defects/${catalogueRef}`,
			data: payload
		})
	},

	getBusinessWarehouseArrivals(businessId, filter) {
		return Axios({
			method: 'GET',
			url: `entity-service/business/business-profile/${businessId}/driver_logged_trips/${filter}`
		})
	},
	getDriverWarehouseArrivals(filter) {
		return Axios({
			method: 'GET',
			url: `warehouse-service/vehicle/arrivals/${filter}`
		})
	},
	confirmWaybill(id, payload) {
		return Axios({
			method: 'POST',
			url: `warehouse-service/vehicle/waybills/confirm/${id}`,
			data: payload
		})
	},
	rejectWaybill(id) {
		return Axios({
			method: 'GET',
			url: `warehouse-service/vehicle/waybills/deny/${id}`,
		})
	},
};

export default ProfileService;
