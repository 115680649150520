export const FETCH_PROFILE_REQUEST = "FETCH_PROFILE_REQUEST";
export const FETCH_PROFILE_SUCCESS = "FETCH_PROFILE_SUCCESS";
export const FETCH_PROFILE_FAILURE = "FETCH_PROFILE_FAILURE";

export const FETCH_ORGANISATIONS_REQUEST = "FETCH_ORGANISATIONS_REQUEST";
export const FETCH_ORGANISATIONS_SUCCESS = "FETCH_ORGANISATIONS_SUCCESS";
export const FETCH_ORGANISATIONS_FAILURE = "FETCH_ORGANISATIONS_FAILURE";

export const FETCH_ORGANISATION_INFO_REQUEST = "FETCH_ORGANISATION_INFO_REQUEST";
export const FETCH_ORGANISATION_INFO_SUCCESS = "FETCH_ORGANISATION_INFO_SUCCESS";
export const FETCH_ORGANISATION_INFO_FAILURE = "FETCH_ORGANISATION_INFO_FAILURE";

export const FETCH_ORGANISATION_MEMBERS_REQUEST = "FETCH_ORGANISATION_MEMBERS_REQUEST";
export const FETCH_ORGANISATION_MEMBERS_SUCCESS = "FETCH_ORGANISATION_MEMBERS_SUCCESS";
export const FETCH_ORGANISATION_MEMBERS_FAILURE = "FETCH_ORGANISATION_MEMBERS_FAILURE";

export const FETCH_BUSINESS_REQUEST = "FETCH_BUSINESS_REQUEST";
export const FETCH_BUSINESS_SUCCESS = "FETCH_BUSINESS_SUCCESS";
export const FETCH_BUSINESS_FAILURE = "FETCH_BUSINESS_FAILURE";

export const FETCH_BUSINESS_INFO_REQUEST = "FETCH_BUSINESS_INFO_REQUEST";
export const FETCH_BUSINESS_INFO_SUCCESS = "FETCH_BUSINESS_INFO_SUCCESS";
export const FETCH_BUSINESS_INFO_FAILURE = "FETCH_BUSINESS_INFO_FAILURE";

export const FETCH_BUSINESS_CONFIG_REQUEST = "FETCH_BUSINESS_CONFIG_REQUEST";
export const FETCH_BUSINESS_CONFIG_SUCCESS = "FETCH_BUSINESS_CONFIG_SUCCESS";
export const FETCH_BUSINESS_CONFIG_FAILURE = "FETCH_BUSINESS_CONFIG_FAILURE";

export const FETCH_DRIVERS_WAREHOUSE_ARRIVAL_REQUEST = "FETCH_DRIVERS_WAREHOUSE_ARRIVAL_REQUEST";
export const FETCH_DRIVERS_WAREHOUSE_ARRIVAL_SUCCESS = "FETCH_DRIVERS_WAREHOUSE_ARRIVAL_SUCCESS";
export const FETCH_DRIVERS_WAREHOUSE_ARRIVAL_FAILURE = "FETCH_DRIVERS_WAREHOUSE_ARRIVAL_FAILURE";

export const FETCH_WAREHOUSE_ARRIVAL_INFORMATION_REQUEST = "FETCH_WAREHOUSE_ARRIVAL_INFORMATION_REQUEST";
export const FETCH_WAREHOUSE_ARRIVAL_INFORMATION_SUCCESS = "FETCH_WAREHOUSE_ARRIVAL_INFORMATION_SUCCESS";
export const FETCH_WAREHOUSE_ARRIVAL_INFORMATION_FAILURE = "FETCH_WAREHOUSE_ARRIVAL_INFORMATION_FAILURE";

export const FETCH_WAREHOUSE_REQUEST = "FETCH_WAREHOUSE_REQUEST";
export const FETCH_WAREHOUSE_SUCCESS = "FETCH_WAREHOUSE_SUCCESS";
export const FETCH_WAREHOUSE_FAILURE = "FETCH_WAREHOUSE_FAILURE";

export const FETCH_WAREHOUSES_REQUEST = "FETCH_WAREHOUSES_REQUEST";
export const FETCH_WAREHOUSES_SUCCESS = "FETCH_WAREHOUSES_SUCCESS";
export const FETCH_WAREHOUSES_FAILURE = "FETCH_WAREHOUSES_FAILURE";

export const FETCH_WAREHOUSE_CATALOGUE_REQUEST = "FETCH_WAREHOUSE_CATALOGUE_REQUEST";
export const FETCH_WAREHOUSE_CATALOGUE_SUCCESS = "FETCH_WAREHOUSE_CATALOGUE_SUCCESS";
export const FETCH_WAREHOUSE_CATALOGUE_FAILURE = "FETCH_WAREHOUSE_CATALOGUE_FAILURE";

export const FETCH_WAREHOUSE_DEFECTS_REQUEST = "FETCH_WAREHOUSE_DEFECTS_REQUEST";
export const FETCH_WAREHOUSE_DEFECTS_SUCCESS = "FETCH_WAREHOUSE_DEFECTS_SUCCESS";
export const FETCH_WAREHOUSE_DEFECTS_FAILURE = "FETCH_WAREHOUSE_DEFECTS_FAILURE";
