import * as ComponentConstants from '../constants/component.constants';

const initialState = {
  appLoader: false,
  sideNavMode: false,
  iconNavMode: false,
  iconNavOnHoverMode: false,
  mobileDropdownMode: false,
  themeMode: "",
};

const componentReducer = (state = initialState, action) => {
  switch (action.type) {

    case ComponentConstants.LOADING_APP:
      return {...state, appLoader: action.payload};

    case ComponentConstants.TOGGLE_MOBILE_NAV:
      return {...state, sideNavMode: action.payload};

    case ComponentConstants.TOGGLE_ICON_NAV:
      return {...state, iconNavMode: action.payload};

    case ComponentConstants.TOGGLE_ICON_NAV_HOVER:
      return {...state, iconNavOnHoverMode: action.payload};

    case ComponentConstants.TOGGLE_THEME_STATE:
      return {...state, themeMode: action.payload};

    case ComponentConstants.TOGGLE_MOBILE_DROPDOWN:
      return {...state, mobileDropdownMode: action.payload};

      
    default:
      return state;
  }
};

export default componentReducer;
