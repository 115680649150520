export const GeneralAppInfo = {
	APP_NAME: "Siju by truQ",
	ORG_NAME: 'Tenant',
	GOOGLE_AUTH_KEY: "846567812607-qr3ivqac95pvkcjebf3744q8tq2banre.apps.googleusercontent.com",
	DODGE_STAGING_BASE_URL: 'https://dodge-apis-staging-c2btvqbhlq-uc.a.run.app',
	DODGE_BASE_URL: 'https://dodge.truq.it',
	/*DODGE_BASE_URL: 'https://dodge-apis-c2btvqbhlq-uc.a.run.app',*/
	LOCALHOST: "localhost",
	PROD_BASEURL_SIJU: "app.siju.it",
	BETA_BASEURL_SIJU: "app-beta.siju.it",
	VERCEL_BASEURL_SIJU: "dodge-multiview.vercel.app",
};

export const AppConfigs = {
	KEY: 'T7uQd45hb04rd-0987Tt1V13wMT4-987to89nC0D30f1529CUlpCyvNmm4r5hyP48SCR36Gp8a1k3KAxfC5JU',
	USER_KEY: 'T7uQd45hb04rd-0987Tt1V13wMT4-987to89D30f15r5hyP48SCR329CUnC0lpCyvNmm46Gp8a1k3KAxfC5JU',
	THEME_KEY: 'T7uQd45hb04rd-0987Tt1V13wMT4-987to89D30f15r8SCCyvNmm4R329CUnC0lp6Gp85hyP4a1k3KfC5AxJU',
	NAV_KEY: 'T7uQd45hb04rd-0987Tt1V13wMT4-987to89D30f1R5r8SCCyvNmm4329CUnCa1k3K0lp6GfC5Axp85hyP4JU',
	USER_ORG: 'T7uQmuL71Vi3w-0987Tt1V13wMT4-987tC0lpCyvNmm4yPD30f152r5hU486Gpo9CCR33KA89n8a1kSxfC5JU',
	ORG_COUNTRY: 'T7uQmuL71Vi3w-0987Tt1V13wMT4-987tCCyvNm0lp153KA89mhU486Gpo42r5yPkSxfC5D30fn89CCR3a1JU',
	USER_TYPE: 'T7uQmuL71Vi3w-0987Tt1V13wMT4-987tC0lpCyvNmm4r5hyPD30f1529CU486Gpo89n8a1kSCR33KAxfC5JU',
	USER_ROLE: 'T7uQmuL71Vi3w-0987Tt1V13wMT4-987tC0lm4hyP48SCR0CU4hyP48SCR8Nma1kSCR33KAx9n8pCyDvfC5JU',
	ORG_INVITATION_KEY: 'T7uQmuL71Vi3w-0987Tt1V13wMT4-987UANcCOsiUyGmuMMLrS27csd45h604rdU2FsdScB5Rp2KX2GVyGmuMMLr',
	ORG_INVITATION_REF: 'T7uQmuL71Vi3w-0987Tt1V13wMT4-987UANcCOsiUyGmuMMLrC0lp6Gp85hyP4a1k3KfC5AxJUyGmuMMLr',
	ONBOARDING_KEY: 'T7uQmuL71Vi3w-0987Tt1V13wMT4-987U3b3r3ANcCOsiUy0nb0Ab1NGmuMMLrC04a1k3KfC5AxJUyGmuMMLr',
	ONBOARDING_INDEX: 'T7uQmuL71Vi3w-0987Tt1V13wMT4-987U3b3r3ANcCOsiUy0nb0Ab1NGrC04a1nD3xC5AxJUyGmuMMLr',
	ACCOUNT_VERIFICATION_KEY: 'T7uQmuL71Vi3w-0987Tt1V13wMT4-987tC0lm4hyaCC0u743R1F1Ca710NSCR33KAx9n8pCyDvfC5JU',
	COOKIE_EXPIRY_PERIOD: 30,
};

/** ALPHABETICAL ORDER */
export const ActionPromptOption = {
	CANCEL_TRIP: "CANCEL_TRIP",
	DELETE_TRIP: "DELETE_TRIP",
	DELETE_ROLE: "DELETE_ROLE",
	TEAM_MEMBER: "TEAM_MEMBER",
	DELETE_ORDER: "DELETE_ORDER",
	REJECT_WAYBILL: "REJECT_WAYBILL",
	DELETE_PRODUCT: "DELETE_PRODUCT",
	ADMIN_MANAGERS: "ADMIN_MANAGERS",
	UNASSIGN_DRIVER: "UNASSIGN_DRIVER",
	ASSIGN_BUSINESS: "ASSIGN_BUSINESS",
	DELETE_ORDER_SET: "DELETE_ORDER_SET",
	DELETE_WAREHOUSE: "DELETE_WAREHOUSE",
	TRANSFER_VEHICLE: "TRANSFER_VEHICLE",
	TRANSFER_BUSINESS: "TRANSFER_BUSINESS",
	WALLET_WITHDRAWALS: "WALLET_WITHDRAWALS",
	REMOVE_SERVICE_PLAN: "REMOVE_SERVICE_PLAN",
	ORGANISATION_PROFILE: "ORGANISATION_PROFILE",
	CHANGE_PRICING_MODEL: "CHANGE_PRICING_MODEL",
	TOGGLE_BUSINESS_TYPE: "TOGGLE_BUSINESS_TYPE",
	DELETE_FLEET_CATEGORY: "DELETE_FLEET_CATEGORY",
	REMOVE_MERCHANT_COUPON: "REMOVE_MERCHANT_COUPON",
	DELETE_TRIP_POOL_CONFIG: "DELETE_TRIP_POOL_CONFIG",
	DELETE_PRODUCT_CATEGORY: "DELETE_PRODUCT_CATEGORY",
	UPDATE_VEHICLE_CATEGORY: "UPDATE_VEHICLE_CATEGORY",
	CHANGE_BUSINESS_VAT_TYPE: "CHANGE_BUSINESS_VAT_TYPE",
	DELETE_BUSINESS_PRICE_CONFIG: "DELETE_BUSINESS_PRICE_CONFIG",
	CHANGE_BUSINESS_FULFILMENT_TYPE: "CHANGE_BUSINESS_FULFILMENT_TYPE",
	UNASSIGN_ACCOUNT_MANAGER: "UNASSIGN_ACCOUNT_MANAGER",
	TOGGLE_BUSINESS_COMMISSION_TYPE: "TOGGLE_BUSINESS_COMMISSION_TYPE"
};

export const AddedServicesOptions = {
	EXTRAS: "EXTRAS",
	COUPON: "COUPON",
};

export const applyCouponOption = {
	TRIP: "TRIP",
	MERCHANT: "MERCHANT",
};

export const CustomTimeType = {
	MONTH: "month",
	QUARTER: "quarter",
	HALF_YEAR: "half_year",
	YEAR: "year",
	CUSTOM_RANGE: "custom_range",
};

export const DeliveryOption = {
	PICKUP: "PICKUP",
	DROPOFF: "DROPOFF"
};

export const DynamicOption = {
	DRIVER: "DRIVER",
	VEHICLE: "VEHICLE",
	BUSINESS: "BUSINESS",
};

export const Environment = {
	LOCAL: "LOCAL",
	SANDBOX: "SANDBOX",
	PRODUCTION: "PRODUCTION",
}

export const ExportDataType = {
	TRIPS: "TRIPS",
	FLEET: "FLEET",
	USERS: "USERS",
	DRIVERS: "DRIVERS",
	BUSINESSES: "BUSINESSES",
	WALLET_WITHDRAWALS: "WALLET_WITHDRAWALS",
	DELIVERY_COLLECTIONS: "DELIVERY_COLLECTIONS",
	ADVANCED_PAYMENTS: "ADVANCED_PAYMENTS",
};

export const FindRecordOption = {
	VEHICLE: "VEHICLE",
	TEAM_ROLE: "TEAM_ROLE",
	DRIVER_TRIP: "DRIVER_TRIP",
	DRIVER_VEHICLE: "DRIVER_VEHICLE",
	ASSIGN_BUSINESS: "ASSIGN_BUSINESS",
	ASSIGN_ORGANISATION_MANAGER: "ASSIGN_ORGANISATION_MANAGER",
	ASSIGN_ORGANISATION_ACCOUNT_MANAGER: "ASSIGN_ACCOUNT_MANAGER",
	ASSIGN_BUSINESS_ACCOUNT_MANAGER: "ASSIGN_BUSINESS_ACCOUNT_MANAGER",
};

export const ImageOptions = {
	PROFILE: 'PROFILE',
	IMAGE: 'IMAGE',
	WAYBILL: 'WAYBILL',
	PROOF_OF_PAYMENT: 'PROOF_OF_PAYMENT',
};

export const InputType = {
	TEXT: 'text',
	EMAIL: 'email',
	OPTIONS: 'options',
	RADIO: 'radio',
	BOOLEAN: 'boolean',
	FILE: 'file',
	NUMBER: 'number',
	DATE: 'date',
};

export const InputValidator = {
	INVALID: 'INVALID',
	VALID: 'VALID'
};

export const Loading = {
	FETCHING: 'FETCHING',
	SUCCESS: 'SUCCESS',
	CREATED: 'CREATED',
	ERROR: 'ERROR',
	FAILED: 'FAILED',
	NO_CONTENT: 204,
	OK: 200,
};

export const LocatorOption = {
	CREATE_ORDER_SET: 'CREATE_ORDER_SET',
	CREATE_WAREHOUSE: 'CREATE_WAREHOUSE',
	UPDATE_WAREHOUSE: 'UPDATE_WAREHOUSE'
};

export const MenuInitialStatus = {
	home: false,
	admins: false,
	organisation: false,
	services: false,
	plan: false,
	drm: false,
	flt: false,
	bok: false,
	wha: false,
	finances: false,
	'user-management': false,
	'audit-trail': false,
};

export const MenuType = {
	BOK: 'bok',
	DRM: 'drm',
	FLT: 'flt',
	IMS: 'ims',
	REC: 'rec',
	WHA: 'wha',
	HOME: 'home',
	PLAN: 'plan',
	ROLES: 'roles',
	AUTH: "auth",
	PROFILE: 'profile',
	COUNTRY: 'country',
	TEAM: 'team-member',
	ORG: 'organisation',
	BILLINGS: 'billings',
	INSIGHTS: 'insights',
	SERVICES: 'services',
	OVERVIEW: 'overview',
	FINANCES: 'finances',
	MARKETPLACE: 'marketplace',
	AUDIT_TRAIL: "audit-trail",
	API_INTEGRATION: 'api_integration',
	USER_MANAGEMENT: 'user-management',
	BUSINESS_MANAGEMENT: 'business-management',
};

export const MethodType = {
	CREATE: "CREATE",
	UPDATE: "UPDATE",
};

export const PresetTimeType = {
	TODAY: "today",
	YESTERDAY: "yesterday",
	CURRENT_WEEK: "current_week",
	LAST_WEEK: "last_week",
	CURRENT_MONTH: "current_month",
	LAST_MONTH: "last_month",
	CURRENT_YEAR: "current_year",
	LAST_YEAR: "last_year",
};

export const PriceConfigTypes = {
	DEFAULT: 'DEFAULT',
	CUSTOM: 'CUSTOM',
	OPEN_TRIP: 'OPEN_TRIP',
	INDIVIDUAL: 'INDIVIDUAL',
	OPEN_TRIP_DEFAULT: 'OPEN_TRIP_DEFAULT',
};

export const QueryTags = {
	PROFILE: 'Profile'
};

export const ServiceType = {
	USR: "USR",
	BOK: "BOK",
	DRM: "DRM",
	FLT: "FLT",
	IMS: "IMS",
	REC: "REC",
	TRP: "TRP",
	WHA: "WHA",
};

export const SubMenuType = {
	ADVANCED_PAYMENTS: "advance-payment-entries",
	WALLET_WITHDRAWALS: "withdrawal-entries",
	DELIVERY_COLLECTIONS: "delivery-collections",
	CONFIGS: "configs",
	REPORTS: "reports",
	WALLETS: "wallets",
	PROFILE: 'profile',
	LOGIN: 'login',
	ADMIN: 'admin',
	INVITE_USER: 'invite-user',
	REGISTER: 'register',
	RESET_PASSWORD: 'reset-password',
	FORGOT_PASSWORD: 'forgot-password',
	ONBOARDING_PROFILE: "onboarding-profile",
	ONBOARD_ORGANISATION: "onboard-organisation",
	WAREHOUSE: "warehouse-automation"
};

export const ThemeMode = {
	LIGHT_MODE: 'light-theme',
	DARK_MODE: 'dark-theme'
};

export const TimeType = {
	PRESET: "PRESET",
	CUSTOM: "CUSTOM",
};

export const TopUpOption = {
	TOPUP: "TOPUP",
	CREDIT: "CREDIT",
};

export const TripStatus = {
	INITIATED: "INITIATED",
	"AWAITING-PAYMENT": "AWAITING-PAYMENT",
	"ORDER-CONFIRMED": "ORDER-CONFIRMED",
	"ASSIGNED-TO-DRIVER": "ASSIGNED-TO-DRIVER",
	"DRIVER-AWAITING-CONFIRMATION": "DRIVER-AWAITING-CONFIRMATION",
	"DRIVER-ARRIVED": "DRIVER-ARRIVED",
	LOADING: "LOADING",
	"TRIP-STARTED": "TRIP-STARTED",
	"TRIP-STARTED-WITH-STOPS": "TRIP-STARTED-WITH-STOPS",
	"AWAITING-DELIVERY-CONFIRMATION": "AWAITING-DELIVERY-CONFIRMATION",
	"DROP-OFF": "DROP-OFF",
	"COMPLETED-OFFLINE": "COMPLETED-OFFLINE",
	COMPLETED: "COMPLETED",
	CANCELLED: "CANCELLED",
	RECONCILED: "RECONCILED",
	INVALID: "INVALID",
	OTHER: "OTHER",
};

export const TripTypeOptions = {
	FIXED: 'FIXED',
	VARIABLE: 'VARIABLE',
	NEGOTIABLE: 'NEGOTIABLE',
	DISTRIBUTION_PER_CITY: 'DISTRIBUTION-PER-CITY',
	DISTRIBUTION_PER_STOP: 'DISTRIBUTION-PER-STOP',
};

export const UserType = {
	ADMIN: "ADMIN",
	TENANT: "TENANT",
	CUSTOM_USER: "CUSTOM_USER",
};

export const WalletOptions = {
	USER: "USER",
	BUSINESS: "BUSINESS",
	ORGANISATION: "ORGANISATION",
};
