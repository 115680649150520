import axios from 'axios';
import cacheHelper from "../helpers/cacheHelper";
import TokenHelper from "../helpers/tokenHelper";
import cookieHelper from "../helpers/cookieHelper";
import { AppConfigs, GeneralAppInfo, MenuType, SubMenuType } from "../helpers/constants";


export const getBaseUrl = () => {
	let web;
	if (window.location.hostname === GeneralAppInfo.LOCALHOST || window.location.hostname === GeneralAppInfo.BETA_BASEURL_SIJU) {
		web = GeneralAppInfo.DODGE_STAGING_BASE_URL;
	} else if (window.location.hostname === GeneralAppInfo.PROD_BASEURL_SIJU || window.location.hostname === GeneralAppInfo.VERCEL_BASEURL_SIJU) {
		web = GeneralAppInfo.DODGE_BASE_URL;
	}

	return { web };
};

const dodgeInstance = axios.create({ baseURL: getBaseUrl().web });

dodgeInstance.interceptors.request.use(config => {
	let token = TokenHelper.getToken(), organisation = TokenHelper.getSelectedOrg();
	let headers = { 'Content-Type': 'application/json', Authorization: `Bearer ${token}`, ...config.headers };
	if (organisation) headers.organisation = organisation;
	if (TokenHelper.checkIfLoggedIn()) config.headers = headers;
	else config.headers = { 'Content-Type': 'application/json', ...config.headers };
	return config;
}, error => Promise.reject(error));

dodgeInstance.interceptors.response.use(response => response, error => {
	let token = TokenHelper.getToken();
	let loginRoute = `/${MenuType.AUTH}/${SubMenuType.LOGIN}`;
	if (error.response && error.response.status && error.response.status === 401 && token) {
		cookieHelper.remove(AppConfigs.KEY);
		cookieHelper.remove(AppConfigs.ORG_COUNTRY);
		cookieHelper.remove(AppConfigs.USER_ORG);
		cacheHelper.clear();
		window.location.href = loginRoute;
	} else return error.response;
});

export { dodgeInstance };
