import { getProfile } from "./profile.action";
import * as AuthConstants from "../constants/auth.constants";

import CacheHelper from "../../utils/helpers/cacheHelper";
import TokenHelper from "../../utils/helpers/tokenHelper";
import CookieHelper from "../../utils/helpers/cookieHelper";
import AuthService from "../../utils/services/auth.service";
import ActionCreator from "../../utils/helpers/actionCreator";
import { toggleNavToIcons, toggleThemeState } from "./component.action";
import { AppConfigs, ThemeMode, UserType, MenuType, SubMenuType } from "../../utils/helpers/constants";

export const getStoreOnReload = store => {
  const currentMode = CookieHelper.get(AppConfigs.THEME_KEY);
  const currentNavMode = CookieHelper.get(AppConfigs.NAV_KEY);
  const navSetter = !currentNavMode ? false : currentNavMode !== "NAV_DISABLED";
  const setter = !currentMode ? ThemeMode.DARK_MODE : currentMode === ThemeMode.LIGHT_MODE ? ThemeMode.DARK_MODE : ThemeMode.LIGHT_MODE;
  store.dispatch(toggleNavToIcons(navSetter));
  store.dispatch(toggleThemeState(setter));
  const tokenExists = TokenHelper.checkIfLoggedIn();
  if (tokenExists) {
    const token = TokenHelper.getToken();
    if (token) store.dispatch(getProfile());
  }
};

export const setLoggedInUser = (userToken) => async dispatch => {
  await TokenHelper.saveToken(userToken);
  await TokenHelper.saveUserType(UserType.TENANT);
  const token = TokenHelper.checkIfLoggedIn();
  if (token) {
    await dispatch(ActionCreator(AuthConstants.SET_LOGGED_IN_USER, true));
    await dispatch(getProfile());
  }
};

export const removeLoggedInUser = () => async dispatch => {
  let loginRoute = `/${MenuType.AUTH}/${SubMenuType.LOGIN}`;
  await AuthService.logout();
  await dispatch(ActionCreator(AuthConstants.LOG_OUT));
  await dispatch(ActionCreator(AuthConstants.SET_LOGGED_IN_USER, false));
  CookieHelper.remove(AppConfigs.KEY);
  CookieHelper.remove(AppConfigs.USER_ORG);
  CookieHelper.remove(AppConfigs.USER_ROLE);
  CookieHelper.remove(AppConfigs.USER_TYPE);
  CookieHelper.remove(AppConfigs.ORG_COUNTRY);
  CookieHelper.remove(AppConfigs.ONBOARDING_KEY);
  CacheHelper.clear();
  window.location.href = loginRoute;
};

export const expiredTokenCheck = (error, dispatch) => {
  // if (error.status === 403) dispatch(removeLoggedInUser());
};
