export const FETCH_SUBSCRIPTION_PLANS_REQUEST = "FETCH_SUBSCRIPTION_PLANS_REQUEST";
export const FETCH_SUBSCRIPTION_PLANS_SUCCESS = "FETCH_SUBSCRIPTION_PLANS_SUCCESS";
export const FETCH_SUBSCRIPTION_PLANS_FAILURE = "FETCH_SUBSCRIPTION_PLANS_FAILURE";

export const FETCH_PLAN_ENTRIES_REQUEST = "FETCH_PLAN_ENTRIES_REQUEST";
export const FETCH_PLAN_ENTRIES_SUCCESS = "FETCH_PLAN_ENTRIES_SUCCESS";
export const FETCH_PLAN_ENTRIES_FAILURE = "FETCH_PLAN_ENTRIES_FAILURE";

export const FETCH_BILLINGS_REQUEST = "FETCH_BILLINGS_REQUEST";
export const FETCH_BILLINGS_SUCCESS = "FETCH_BILLINGS_SUCCESS";
export const FETCH_BILLINGS_FAILURE = "FETCH_BILLINGS_FAILURE";

export const FETCH_PLAN_SERVICES_REQUEST = "FETCH_PLAN_SERVICES_REQUEST";
export const FETCH_PLAN_SERVICES_SUCCESS = "FETCH_PLAN_SERVICES_SUCCESS";
export const FETCH_PLAN_SERVICES_FAILURE = "FETCH_PLAN_SERVICES_FAILURE";
