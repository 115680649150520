import {Loading} from "../../utils/helpers/constants";
import * as ProfileConstants from "../constants/profile.constants";
import {formatBusinesses, formatOrganisation, formatUser} from "../../utils/helpers/logicHelper";

const initialState = {
  userData: {},
  permissions: {},
  organisations: [],
  businesses: [],
  organisation: {},
  businessInfo: {},
  businessConfig: [],
  organisationMembers: [],
  warehouse:{},
  warehouses: [],
  warehouseArrivals: [],
  warehouseArrivalInfo: {},
  warehouseCatalogue: {},
  warehouseDefects: {},
  userId: "",
  loading: "",
  loadingBusinesses: "",
  loadingWarehouse: "",
  loadingWarehouses: "",
  loadingOrganisationInfo: "",
  loadingWarehouseArrivals: "",
  loadingWarehouseArrivalInfo: "",
  loadingBusinessInfo: "",
  loadingBusinessConfig: "",
  loadingOrganisationMembers: "",
  loadingWarehouseCatalogue: "",
  loadingWarehouseDefects: "",
  businessesCount: "",
  organisationsCount: "",
  warehouseDefectsCount: "",
  warehouseCatalogueCount: "",
};

const profileReducer = (state = initialState, action) => {
  switch(action.type) {
    case ProfileConstants.FETCH_PROFILE_REQUEST:
      return {...state, loading: Loading.FETCHING};

    case ProfileConstants.FETCH_PROFILE_SUCCESS:
      return {
        ...state,
        loading: Loading.SUCCESS,
        userData: action.payload,
        userId: action.payload.id,
        permissions: action.payload.permissions
      };

    case ProfileConstants.FETCH_PROFILE_FAILURE:
      return {...state, loading: Loading.ERROR}


    case ProfileConstants.FETCH_ORGANISATIONS_REQUEST:
      return {...state, loadingOrganisations: Loading.FETCHING};

    case ProfileConstants.FETCH_ORGANISATIONS_SUCCESS:
      const formattedOrganisation = formatOrganisation(action.payload.data);
      return {
        ...state,
        loadingOrganisations: Loading.SUCCESS,
        organisations: formattedOrganisation,
        organisationsCount: action.payload.count ?? action.payload.data.length ,
      };

    case ProfileConstants.FETCH_ORGANISATIONS_FAILURE:
      return {...state, loadingOrganisations: Loading.ERROR};

    case ProfileConstants.FETCH_ORGANISATION_INFO_REQUEST:
      return {...state, loadingOrganisationInfo: Loading.FETCHING};

    case ProfileConstants.FETCH_ORGANISATION_INFO_SUCCESS:
      return {...state, loadingOrganisationInfo: Loading.SUCCESS, organisation: action.payload};

    case ProfileConstants.FETCH_ORGANISATION_INFO_FAILURE:
      return {...state, loadingOrganisationInfo: Loading.ERROR};

    case ProfileConstants.FETCH_ORGANISATION_MEMBERS_REQUEST:
      return {...state, loadingOrganisationMembers: Loading.FETCHING};

    case ProfileConstants.FETCH_ORGANISATION_MEMBERS_SUCCESS:
      const organisationMembers = formatUser(action.payload);
      return {...state, loadingOrganisationMembers: Loading.SUCCESS, organisationMembers};

    case ProfileConstants.FETCH_ORGANISATION_MEMBERS_FAILURE:
      return {...state, loadingOrganisationMembers: Loading.ERROR};

    case ProfileConstants.FETCH_BUSINESS_REQUEST:
      return {...state, loadingBusinesses: Loading.FETCHING};

    case ProfileConstants.FETCH_BUSINESS_SUCCESS:
      const businesses = formatBusinesses(action.payload.data);
      return {
        ...state, loadingBusinesses: Loading.SUCCESS, businesses, businessesCount: action.payload.count
      };

    case ProfileConstants.FETCH_BUSINESS_FAILURE:
      return {...state, loadingBusinesses: Loading.ERROR};

    case ProfileConstants.FETCH_BUSINESS_INFO_REQUEST:
      return {...state, loadingBusinessInfo: Loading.FETCHING};

    case ProfileConstants.FETCH_BUSINESS_INFO_SUCCESS:
      return {...state, loadingBusinessInfo: Loading.SUCCESS, businessInfo: action.payload.data};

    case ProfileConstants.FETCH_BUSINESS_INFO_FAILURE:
      return {...state, loadingBusinessInfo: Loading.ERROR};

    case ProfileConstants.FETCH_BUSINESS_CONFIG_REQUEST:
      return {...state, loadingBusinessConfig: Loading.FETCHING};

    case ProfileConstants.FETCH_BUSINESS_CONFIG_SUCCESS:
      return {...state, loadingBusinessConfig: Loading.SUCCESS, businessConfig: action.payload};

    case ProfileConstants.FETCH_BUSINESS_CONFIG_FAILURE:
      return {...state, loadingBusinessConfig: Loading.ERROR};

    case ProfileConstants.FETCH_DRIVERS_WAREHOUSE_ARRIVAL_REQUEST:
      return {...state, loadingWarehouseArrivals: Loading.FETCHING};

    case ProfileConstants.FETCH_DRIVERS_WAREHOUSE_ARRIVAL_SUCCESS:
      return {
        ...state,
        loadingWarehouseArrivals: Loading.SUCCESS,
        warehouseArrivalCount: action.payload.count,
        warehouseArrivals: action.payload.data
      }

    case ProfileConstants.FETCH_DRIVERS_WAREHOUSE_ARRIVAL_FAILURE:
      return {...state, loadingWarehouseArrivals: Loading.ERROR};

    case ProfileConstants.FETCH_WAREHOUSE_ARRIVAL_INFORMATION_REQUEST:
      return {...state, loadingWarehouseArrivalInfo: Loading.FETCHING};

    case ProfileConstants.FETCH_WAREHOUSE_ARRIVAL_INFORMATION_SUCCESS:
      return {...state, loadingWarehouseArrivalInfo: Loading.SUCCESS, warehouseArrivalInfo: action.payload}

    case ProfileConstants.FETCH_WAREHOUSE_ARRIVAL_INFORMATION_FAILURE:
      return {...state, loadingWarehouseArrivalInfo: Loading.ERROR};

    case ProfileConstants.FETCH_WAREHOUSE_REQUEST:
      return {...state, loadingWarehouse: Loading.FETCHING};

    case ProfileConstants.FETCH_WAREHOUSE_SUCCESS:
      return {
        ...state,
        loadingWarehouse: Loading.SUCCESS,
        warehouse: action.payload
      }

    case ProfileConstants.FETCH_WAREHOUSE_FAILURE:
      return {...state, loadingWarehouse: Loading.ERROR};

    case ProfileConstants.FETCH_WAREHOUSES_REQUEST:
      return {...state, loadingWarehouses: Loading.FETCHING};

    case ProfileConstants.FETCH_WAREHOUSES_SUCCESS:
      return {
        ...state,
        loadingWarehouses: Loading.SUCCESS,
        warehouses: action.payload.data
      }

    case ProfileConstants.FETCH_WAREHOUSES_FAILURE:
      return {...state, loadingWarehouses: Loading.ERROR};

    case ProfileConstants.FETCH_WAREHOUSE_CATALOGUE_REQUEST:
      return {...state, loadingWarehouseCatalogue: Loading.FETCHING};

    case ProfileConstants.FETCH_WAREHOUSE_CATALOGUE_SUCCESS:
      return {
        ...state,
        loadingWarehouseCatalogue: Loading.SUCCESS,
        warehouseCatalogueCount: action.payload.count,
        warehouseCatalogue: action.payload.data
      }

    case ProfileConstants.FETCH_WAREHOUSE_CATALOGUE_FAILURE:
      return {...state, loadingWarehouseCatalogue: Loading.ERROR};

      case ProfileConstants.FETCH_WAREHOUSE_DEFECTS_REQUEST:
      return {...state, loadingWarehouseDefects: Loading.FETCHING};

    case ProfileConstants.FETCH_WAREHOUSE_DEFECTS_SUCCESS:
      return {
        ...state,
        loadingWarehouseDefects: Loading.SUCCESS,
        warehouseDefectsCount: action.payload.count,
        warehouseDefects: action.payload.data
      }

    case ProfileConstants.FETCH_WAREHOUSE_DEFECTS_FAILURE:
      return {...state, loadingWarehouseDefects: Loading.ERROR};


    case 'RESET_STATE':
      return initialState;

    default:
      return state;
  }
};

export default profileReducer;
