export const FETCH_SUPPORTED_COUNTRIES_REQUEST = "FETCH_SUPPORTED_COUNTRIES_REQUEST";
export const FETCH_SUPPORTED_COUNTRIES_SUCCESS = "FETCH_SUPPORTED_COUNTRIES_SUCCESS";
export const FETCH_SUPPORTED_COUNTRIES_FAILURE = "FETCH_SUPPORTED_COUNTRIES_FAILURE";

export const FETCH_SUPPORTED_COUNTRY_REQUEST = "FETCH_SUPPORTED_COUNTRY_REQUEST";
export const FETCH_SUPPORTED_COUNTRY_SUCCESS = "FETCH_SUPPORTED_COUNTRY_SUCCESS";
export const FETCH_SUPPORTED_COUNTRY_FAILURE = "FETCH_SUPPORTED_COUNTRY_FAILURE";

export const FETCH_ALL_SERVICE_REQUEST = "FETCH_ALL_SERVICE_REQUEST";
export const FETCH_ALL_SERVICE_SUCCESS = "FETCH_ALL_SERVICE_SUCCESS";
export const FETCH_ALL_SERVICE_FAILURE = "FETCH_ALL_SERVICE_FAILURE";

export const FETCH_SERVICE_REQUEST = "FETCH_SERVICE_REQUEST";
export const FETCH_SERVICE_SUCCESS = "FETCH_SERVICE_SUCCESS";
export const FETCH_SERVICE_FAILURE = "FETCH_SERVICE_FAILURE";

export const FETCH_COUNTRY_SERVICES_REQUEST = "FETCH_COUNTRY_SERVICES_REQUEST";
export const FETCH_COUNTRY_SERVICES_SUCCESS = "FETCH_COUNTRY_SERVICES_SUCCESS";
export const FETCH_COUNTRY_SERVICES_FAILURE = "FETCH_COUNTRY_SERVICES_FAILURE";

export const FETCH_INSURANCE_TYPES_REQUEST = "FETCH_INSURANCE_TYPES_REQUEST";
export const FETCH_INSURANCE_TYPES_SUCCESS = "FETCH_INSURANCE_TYPES_SUCCESS";
export const FETCH_INSURANCE_TYPES_FAILURE = "FETCH_INSURANCE_TYPES_FAILURE";

export const FETCH_ORGANISATION_SERVICES_REQUEST = "FETCH_ORGANISATION_SERVICES_REQUEST";
export const FETCH_ORGANISATION_SERVICES_SUCCESS = "FETCH_ORGANISATION_SERVICES_SUCCESS";
export const FETCH_ORGANISATION_SERVICES_FAILURE = "FETCH_ORGANISATION_SERVICES_FAILURE";

export const FETCH_RECOMMENDED_SERVICES_REQUEST = "FETCH_RECOMMENDED_SERVICES_REQUEST";
export const FETCH_RECOMMENDED_SERVICES_SUCCESS = "FETCH_RECOMMENDED_SERVICES_SUCCESS";
export const FETCH_RECOMMENDED_SERVICES_FAILURE = "FETCH_RECOMMENDED_SERVICES_FAILURE";

export const FETCH_INTEGRATION_KEYS_REQUEST = "FETCH_INTEGRATION_KEYS_REQUEST";
export const FETCH_INTEGRATION_KEYS_SUCCESS = "FETCH_INTEGRATION_KEYS_SUCCESS";
export const FETCH_INTEGRATION_KEYS_FAILURE = "FETCH_INTEGRATION_KEYS_FAILURE";

export const FETCH_WEBHOOK_EVENTS_REQUEST = "FETCH_WEBHOOK_EVENTS_REQUEST";
export const FETCH_WEBHOOK_EVENTS_SUCCESS = "FETCH_WEBHOOK_EVENTS_SUCCESS";
export const FETCH_WEBHOOK_EVENTS_FAILURE = "FETCH_WEBHOOK_EVENTS_FAILURE";
