import {Loading} from "../../utils/helpers/constants";
import * as Constants from "../constants/fleet.constants";
import {formatFleetCategories} from "../../utils/helpers/logicHelper";


const initialState = {
  fleet: [],
  vehicle: {},
  fleetProfile: {},
  fleetCategories: [],
  categoriesCharge: [],
  categoriesChargeCount: "",
  fleetCount: "",
  loadingFleet: "",
  loadingVehicle: "",
  loadingFleetProfile: "",
  loadingFleetCategories: "",
  loadingCategoriesCharge: "",
};

const fleetReducer = (state = initialState, action) => {
  switch (action.type) {

    case Constants.FETCH_VEHICLE_REQUEST:
      return {...state, loadingVehicle: Loading.FETCHING};

    case Constants.FETCH_VEHICLE_SUCCESS:
      return {...state, loadingVehicle: Loading.SUCCESS, vehicle: action.payload}

    case Constants.FETCH_VEHICLE_FAILURE:
      return {...state, loadingVehicle: Loading.ERROR};

    case Constants.FETCH_FLEET_REQUEST:
      return {...state, loadingFleet: Loading.FETCHING};

    case Constants.FETCH_FLEET_SUCCESS:
      return {
        ...state, loadingFleet: Loading.SUCCESS, fleet: action.payload.data, fleetCount: action.payload.count
      }

    case Constants.FETCH_FLEET_FAILURE:
      return {...state, loadingFleet: Loading.ERROR};

    case Constants.FETCH_FLEET_ORGANISATION_PROFILE_REQUEST:
      return {...state, loadingFleetProfile: Loading.FETCHING};

    case Constants.FETCH_FLEET_ORGANISATION_PROFILE_SUCCESS:
      let fleetProfile = action.payload.length < 1 ? {} : action.payload[0];
      return {...state, loadingFleetProfile: Loading.SUCCESS, fleetProfile};

    case Constants.FETCH_FLEET_ORGANISATION_PROFILE_FAILURE:
      return {...state, loadingFleetProfile: Loading.ERROR};

    case Constants.FETCH_FLEET_CATEGORIES_REQUEST:
      return {...state, loadingFleetCategories: Loading.FETCHING};

    case Constants.FETCH_FLEET_CATEGORIES_SUCCESS:
      const fleetCategories = formatFleetCategories(action.payload);
      return {...state, loadingFleetCategories: Loading.SUCCESS, fleetCategories};

    case Constants.FETCH_FLEET_CATEGORIES_FAILURE:
      return {...state, loadingFleetCategories: Loading.ERROR};

    case Constants.FETCH_CATEGORIES_CHARGE_REQUEST:
      return {...state, loadingCategoriesCharge: Loading.FETCHING};

    case Constants.FETCH_CATEGORIES_CHARGE_SUCCESS:
      return {
        ...state,
        loadingCategoriesCharge: Loading.SUCCESS,
        categoriesCharge: action.payload.data,
        categoriesChargeCount: action.payload.count
      };

    case Constants.FETCH_CATEGORIES_CHARGE_FAILURE:
      return {...state, loadingCategoriesCharge: Loading.ERROR};

    case 'RESET_STATE':
      return initialState;

    default:
      return state;
  }
}

export default fleetReducer;
