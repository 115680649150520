import * as Constants from "../constants/analytics.constants";
import {Loading} from "../../utils/helpers/constants";
import TokenHelper from "../../utils/helpers/tokenHelper";

const initialState = {
	overview: {},
	composite: {},
	onboardingStatus: {},
	loadingOverview: "",
	loadingComposite: "",
	loadingOnboardingStatus: "",
};

const analyticsReducer = (state = initialState, action) => {
	switch(action.type) {

		case Constants.FETCH_OVERVIEW_REQUEST:
			return {...state, loadingOverview: Loading.FETCHING};

		case Constants.FETCH_OVERVIEW_SUCCESS:
			return {...state, loadingOverview: Loading.SUCCESS, overview: action.payload};

		case Constants.FETCH_OVERVIEW_FAILURE:
			return {...state, loadingOverview: Loading.ERROR};

		case Constants.FETCH_ONBOARDING_REQUEST:
			return {...state, loadingOnboardingStatus: Loading.FETCHING};

		case Constants.FETCH_ONBOARDING_SUCCESS:
			const onboarded = action.payload.onboardingStatus ? 'ONBOARDED' : 'PENDING';
			TokenHelper.saveOnboardingStatus(onboarded);
			return {...state, loadingOnboardingStatus: Loading.SUCCESS, onboardingStatus: action.payload};

		case Constants.FETCH_ONBOARDING_FAILURE:
			return {...state, loadingOnboardingStatus: Loading.ERROR};

		case Constants.FETCH_COMPOSITE_REQUEST:
			return {...state, loadingComposite: Loading.FETCHING};

		case Constants.FETCH_COMPOSITE_SUCCESS:
			return {...state, loadingComposite: Loading.SUCCESS, composite: action.payload};

		case Constants.FETCH_COMPOSITE_FAILURE:
			return {...state, loadingComposite: Loading.ERROR};


		case 'RESET_STATE':
			return initialState;

		default:
			return state;
	}
}

export default analyticsReducer;
