import {dodgeInstance as Axios} from './axios.service';

const AuthService = {
  login(payload, platform) {
    return Axios({
      method: 'POST',
      url: `locale-service/auth/login`,
      data: payload,
      headers: {platform: `${platform}`},
    });
  },

  validatePassword(payload, platform) {
    return Axios({
      method: 'POST',
      url: `locale-service/auth/validate-user-password`,
      data: payload,
      headers: {platform: `${platform}`},
    });
  },

  validateOAuth(payload, platform) {
    return Axios({
      method: 'POST',
      url: `locale-service/auth/social-login`,
      data: payload,
      headers: {platform: `${platform}`},
    });
  },

  forgotPassword(payload, platform) {
    return Axios({
      method: 'POST',
      url: `locale-service/auth/forgot-password`,
      data: payload,
      headers: {platform: `${platform}`},
    });
  },

  resetPassword(payload, platform) {
    return Axios({
      method: 'POST',
      url: `locale-service/auth/reset-password`,
      data: payload,
      headers: {platform: `${platform}`},
    });
  },

  logout() {
    return Axios({
      method: 'GET',
      url: `locale-service/auth/logout`,
    });
  },

};

export default AuthService;
