import Cookies from "js-cookie";
import encryptHelper from "./encryptHelper";
import {setKey} from "./logicHelper";

const cookieHelper = {

  get: key => {
    const updatedKey = setKey(key);
    const dataEncrypted = Cookies.get(updatedKey);
    return dataEncrypted !== 'null' ? encryptHelper.decrypt(Cookies.get(updatedKey)) : '';
  },

  set: (key, data, ex) => {
    const dataEncrypted = encryptHelper.encrypt(data);
    Cookies.set(setKey(key), dataEncrypted, {expires: ex});
  },

  remove: key => {
    Cookies.remove(setKey(key));
  }

}

export default cookieHelper;


