export const FETCH_OVERVIEW_REQUEST = "FETCH_OVERVIEW_REQUEST";
export const FETCH_OVERVIEW_SUCCESS = "FETCH_OVERVIEW_SUCCESS";
export const FETCH_OVERVIEW_FAILURE = "FETCH_OVERVIEW_FAILURE";

export const FETCH_COMPOSITE_REQUEST = "FETCH_COMPOSITE_REQUEST";
export const FETCH_COMPOSITE_SUCCESS = "FETCH_COMPOSITE_SUCCESS";
export const FETCH_COMPOSITE_FAILURE = "FETCH_COMPOSITE_FAILURE";

export const FETCH_ONBOARDING_REQUEST = "FETCH_ONBOARDING_REQUEST";
export const FETCH_ONBOARDING_SUCCESS = "FETCH_ONBOARDING_SUCCESS";
export const FETCH_ONBOARDING_FAILURE = "FETCH_ONBOARDING_FAILURE";

