import encryptHelper from "./encryptHelper";
import { setKey } from "./logicHelper";

const cacheHelper = {
  get: key => encryptHelper.decrypt(localStorage.getItem(setKey(key))),

  set: (key, data) => {
    const dataEncrypted = encryptHelper.encrypt(data);
    localStorage.setItem(setKey(key), dataEncrypted);
  },

  remove: key => localStorage.removeItem(setKey(key)),

  clear: () => localStorage.clear()

};

export default cacheHelper;
