import * as SubscriptionConstants from "../constants/subscription.constants";
import {Loading} from "../../utils/helpers/constants";
import {formatSubscriptionPlan} from "../../utils/helpers/logicHelper";

const initialState = {
  subscriptionPlans: [],
  planEntries: [],
  billings: [],
  planServices: {},
  loadingSubscriptionPlans: "",
  loadingPlanEntries: "",
  loadingBillings: "",
  loadingPlanServices: "",
  subscriptionPlansCount: "",
};

const subscriptionReducer = (state = initialState, action) => {
  switch (action.type) {
    case SubscriptionConstants.FETCH_SUBSCRIPTION_PLANS_REQUEST:
      return {...state, loadingSubscriptionPlans: Loading.FETCHING};

    case SubscriptionConstants.FETCH_SUBSCRIPTION_PLANS_SUCCESS:
      let subscriptionPlans = formatSubscriptionPlan(action.payload.data)
      return {
        ...state,
        loadingSubscriptionPlans: Loading.SUCCESS,
        subscriptionPlansCount: action.payload.count,
        subscriptionPlans
      };

    case SubscriptionConstants.FETCH_SUBSCRIPTION_PLANS_FAILURE:
      return {...state, loadingSubscriptionPlans: Loading.ERROR};

    case SubscriptionConstants.FETCH_PLAN_SERVICES_REQUEST:
      return {...state, loadingPlanServices: Loading.FETCHING};

    case SubscriptionConstants.FETCH_PLAN_SERVICES_SUCCESS:
      return {...state, loadingPlanServices: Loading.SUCCESS, planServices: action.payload};

    case SubscriptionConstants.FETCH_PLAN_SERVICES_FAILURE:
      return {...state, loadingPlanServices: Loading.ERROR};

    case SubscriptionConstants.FETCH_PLAN_ENTRIES_REQUEST:
      return {...state, loadingPlanEntries: Loading.FETCHING};

    case SubscriptionConstants.FETCH_PLAN_ENTRIES_SUCCESS:
      return {...state, loadingPlanEntries: Loading.SUCCESS, planEntries: action.payload};

    case SubscriptionConstants.FETCH_PLAN_ENTRIES_FAILURE:
      return {...state, loadingPlanEntries: Loading.ERROR};

    case SubscriptionConstants.FETCH_BILLINGS_REQUEST:
      return {...state, loadingBillings: Loading.FETCHING};

    case SubscriptionConstants.FETCH_BILLINGS_SUCCESS:
      return {...state, loadingBillings: Loading.SUCCESS, billings: action.payload};

    case SubscriptionConstants.FETCH_BILLINGS_FAILURE:
      return {...state, loadingBillings: Loading.ERROR};


    case 'RESET_STATE':
      return initialState;

    default:
      return state;
  }
};

export default subscriptionReducer;
