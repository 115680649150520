import {Loading} from "../../utils/helpers/constants";
import * as FinanceConstants from "../constants/finance.constants";

const initialState = {
	banks: [],
	wallets: [],
	activityLogs: [],
	wallet: {},
	usersWallet: [],
	walletEntries: [],
	walletUserEntries: [],
	saasEntries: [],
	saasRecords: [],
	advancedPayments: [],
	withdrawalEntries: [],
	deliveryCollections: [],
	loadingBanks: "",
	loadingActivityLogs: [],
	sassEntriesCount: "",
	advancedPaymentsCount: "",
	activityLogsCount: "",
	loadingWallets: "",
	loadingWallet: "",
	loadingOrganisationUsersWallet: "",
	loadingWalletEntries: "",
	loadingWalletUserEntries: "",
	organisationWalletCount: "",
	withdrawalEntriesCount: "",
	deliveryCollectionsCount: "",
	organisationUsersWalletCount: "",
	organisationUsersEntriesCount: "",
	organisationEntriesCount: "",
	loadingSaaSRecords: "",
	loadingSaaSEntries: "",
	loadingWithdrawalEntries: "",
	loadingDeliveryCollections: "",
	loadingAdvancedPayments: "",
};

const financeReducers = (state = initialState, action) => {
	switch(action.type) {
		case FinanceConstants.FETCH_BANKS_REQUEST:
			return {...state, loadingBanks: Loading.FETCHING};

		case FinanceConstants.FETCH_BANKS_SUCCESS:
			return {...state, loadingBanks: Loading.SUCCESS, banks: action.payload};

		case FinanceConstants.FETCH_BANKS_FAILURE:
			return {...state, loadingBanks: Loading.ERROR};

		case FinanceConstants.FETCH_WALLETS_REQUEST:
			return {...state, loadingWallets: Loading.FETCHING};

		case FinanceConstants.FETCH_WALLETS_SUCCESS:
			return {
				...state,
				loadingWallets: Loading.SUCCESS,
				organisationWalletCount: action.payload.count,
				wallets: action.payload.data
			};

		case FinanceConstants.FETCH_WALLETS_FAILURE:
			return {...state, loadingWallets: Loading.ERROR};

		case FinanceConstants.FETCH_WALLET_REQUEST:
			return {...state, loadingWallet: Loading.FETCHING};

		case FinanceConstants.FETCH_WALLET_SUCCESS:
			return {...state, loadingWallet: Loading.SUCCESS, wallet: action.payload};

		case FinanceConstants.FETCH_WALLET_FAILURE:
			return {...state, loadingWallet: Loading.ERROR};

		case FinanceConstants.FETCH_ORGANISATION_USERS_REQUEST:
			return {...state, loadingOrganisationUsersWallet: Loading.FETCHING};

		case FinanceConstants.FETCH_ORGANISATION_USERS_SUCCESS:
			return {
				...state,
				loadingOrganisationUsersWallet: Loading.SUCCESS,
				organisationUsersWalletCount: action.payload.count,
				usersWallet: action.payload.data
			};

		case FinanceConstants.FETCH_ORGANISATION_USERS_FAILURE:
			return {...state, loadingOrganisationUsersWallet: Loading.ERROR};

		case FinanceConstants.FETCH_ORGANISATION_WALLET_ENTRIES_REQUEST:
			return {...state, loadingWalletEntries: Loading.FETCHING};

		case FinanceConstants.FETCH_ORGANISATION_WALLET_ENTRIES_SUCCESS:
			return {
				...state, loadingWalletEntries: Loading.SUCCESS,
				organisationEntriesCount: action.payload.count, walletEntries: action.payload.data
			};

		case FinanceConstants.FETCH_ORGANISATION_WALLET_ENTRIES_FAILURE:
			return {...state, loadingWalletEntries: Loading.ERROR};

		case FinanceConstants.FETCH_ORGANISATION_USER_ENTRIES_REQUEST:
			return {...state, loadingWalletUserEntries: Loading.FETCHING};

		case FinanceConstants.FETCH_ORGANISATION_USER_ENTRIES_SUCCESS:
			return {
				...state,
				loadingWalletUserEntries: Loading.SUCCESS,
				organisationUsersEntriesCount: action.payload.count,
				walletUserEntries: action.payload.data
			};

		case FinanceConstants.FETCH_ORGANISATION_USER_ENTRIES_FAILURE:
			return {...state, loadingWalletUserEntries: Loading.ERROR};

		case FinanceConstants.FETCH_SASS_ENTRIES_REQUEST:
			return {...state, loadingSaaSEntries: Loading.FETCHING};

		case FinanceConstants.FETCH_SASS_ENTRIES_SUCCESS:
			return {
				...state,
				loadingSaaSEntries: Loading.SUCCESS,
				saasEntries: action.payload.data,
				sassEntriesCount: action.payload.count
			};

		case FinanceConstants.FETCH_SASS_ENTRIES_FAILURE:
			return {...state, loadingSaaSEntries: Loading.ERROR};

		case FinanceConstants.FETCH_SAAS_RECORDS_REQUEST:
			return {...state, loadingSaaSRecords: Loading.FETCHING};

		case FinanceConstants.FETCH_SAAS_RECORDS_SUCCESS:
			return {...state, loadingSaaSRecords: Loading.SUCCESS, saasRecords: action.payload};

		case FinanceConstants.FETCH_SAAS_RECORDS_FAILURE:
			return {...state, loadingSaaSRecords: Loading.ERROR};

		case FinanceConstants.FETCH_WITHDRAWAL_ENTRIES_REQUEST:
			return {...state, loadingWithdrawalEntries: Loading.FETCHING};

		case FinanceConstants.FETCH_WITHDRAWAL_ENTRIES_SUCCESS:
			return {
				...state, loadingWithdrawalEntries: Loading.SUCCESS,
				withdrawalEntries: action.payload.data, withdrawalEntriesCount: action.payload.count
			};

		case FinanceConstants.FETCH_WITHDRAWAL_ENTRIES_FAILURE:
			return {...state, loadingWithdrawalEntries: Loading.ERROR};

		case FinanceConstants.FETCH_DELIVERY_COLLECTIONS_REQUEST:
			return {...state, loadingDeliveryCollections: Loading.FETCHING};

		case FinanceConstants.FETCH_DELIVERY_COLLECTIONS_SUCCESS:
			return {
				...state, loadingDeliveryCollections: Loading.SUCCESS,
				deliveryCollections: action.payload.data, deliveryCollectionsCount: action.payload.count
			};

		case FinanceConstants.FETCH_DELIVERY_COLLECTIONS_FAILURE:
			return {...state, loadingDeliveryCollections: Loading.ERROR};



		case FinanceConstants.FETCH_ADVANCED_PAYMENTS_REQUEST:
			return {...state, loadingAdvancedPayments: Loading.FETCHING};

		case FinanceConstants.FETCH_ADVANCED_PAYMENTS_SUCCESS:
			return {
				...state, loadingAdvancedPayments: Loading.SUCCESS,
				advancedPayments: action.payload.data,
				advancedPaymentsCount: action.payload.count
			};

		case FinanceConstants.FETCH_ADVANCED_PAYMENTS_FAILURE:
			return {...state, loadingAdvancedPayments: Loading.ERROR, 
				advancedPayments: initialState.advancedPayments, 
				advancedPaymentsCount: initialState.advancedPaymentsCount
			};

		case FinanceConstants.FETCH_ACTIVITY_LOG_REQUEST:
			return { ...state, loadingActivityLogs: Loading.FETCHING };

		case FinanceConstants.FETCH_ACTIVITY_LOG_SUCCESS:
			return {
				...state, loadingActivityLogs: Loading.SUCCESS,
				activityLogs: action.payload.data,
				activityLogsCount: action.payload.count
			};

		case FinanceConstants.FETCH_ACTIVITY_LOG_FAILURE:
			return {
				...state, loadingActivityLogs: Loading.ERROR,
				activityLogs: initialState.activityLogs,
				activityLogsCount: initialState.activityLogsCount
			};
		case 'RESET_STATE':
			return initialState;

		default:
			return state;
	}
};

export default financeReducers;
