import ActionCreator from "../../utils/helpers/actionCreator";
import * as ComponentConstants from '../constants/component.constants';
import cookieHelper from "../../utils/helpers/cookieHelper";
import { AppConfigs, ThemeMode } from "../../utils/helpers/constants";


export const loadingApp = data => async dispatch => {
  dispatch(ActionCreator(ComponentConstants.LOADING_APP, data));
};

export const toggleNav = data => async dispatch => {
  const body = document.body;
  if (body && data) body.classList.add("main-sidebar-show");
  else if (body && !data) body.classList.remove("main-sidebar-show");
  dispatch(ActionCreator(ComponentConstants.TOGGLE_MOBILE_NAV, data));
};

export const toggleNavToIconsOnHover = (data, iconNavMode) => async dispatch => {
  const body = document.body;
  if (body && data) {
    body.classList.add("main-sidebar-open");
    if (!iconNavMode) body.classList.add("main-sidebar-hide");
  } else if (body && !data) {
    body.classList.remove("main-sidebar-open");
    if (!iconNavMode) body.classList.add("main-sidebar-hide");
  }
  dispatch(ActionCreator(ComponentConstants.TOGGLE_ICON_NAV_HOVER, data));
};

export const toggleNavToIcons = data => async dispatch => {
  const body = document.body;
  const cookieValue = data ? "NAV_ENABLED" : "NAV_DISABLED";
  cookieHelper.set(AppConfigs.NAV_KEY, cookieValue, 1);
  if (body && data) body.classList.add("main-sidebar-hide");
  else if (body && !data) body.classList.remove("main-sidebar-hide");
  dispatch(ActionCreator(ComponentConstants.TOGGLE_ICON_NAV, data));
};

export const toggleThemeState = state => async dispatch => {
  const type = state === ThemeMode.LIGHT_MODE ? ThemeMode.DARK_MODE : ThemeMode.LIGHT_MODE;

  if (type === ThemeMode.DARK_MODE) {
    const body = document.body;
    if (body) {
      body.classList.add(ThemeMode.DARK_MODE);
      body.classList.remove(ThemeMode.LIGHT_MODE);
    }
    cookieHelper.set(AppConfigs.THEME_KEY, ThemeMode.DARK_MODE, 1);
    dispatch(ActionCreator(ComponentConstants.TOGGLE_THEME_STATE, ThemeMode.DARK_MODE));
  } else {
    const body = document.body;
    if (body) {
      body.classList.add(ThemeMode.LIGHT_MODE);
      body.classList.remove(ThemeMode.DARK_MODE);
    }
    cookieHelper.set(AppConfigs.THEME_KEY, ThemeMode.LIGHT_MODE, 1);
    dispatch(ActionCreator(ComponentConstants.TOGGLE_THEME_STATE, ThemeMode.LIGHT_MODE));
  }
};

export const toggleMobileDropdownNav = data => async dispatch => {
  dispatch(ActionCreator(ComponentConstants.TOGGLE_MOBILE_DROPDOWN, data));
};

