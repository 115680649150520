export const FETCH_BANKS_REQUEST = "FETCH_BANKS_REQUEST";
export const FETCH_BANKS_SUCCESS = "FETCH_BANKS_SUCCESS";
export const FETCH_BANKS_FAILURE = "FETCH_BANKS_FAILURE";

export const FETCH_WALLETS_REQUEST = "FETCH_WALLETS_REQUEST";
export const FETCH_WALLETS_SUCCESS = "FETCH_WALLETS_SUCCESS";
export const FETCH_WALLETS_FAILURE = "FETCH_WALLETS_FAILURE";

export const FETCH_WALLET_REQUEST = "FETCH_WALLET_REQUEST";
export const FETCH_WALLET_SUCCESS = "FETCH_WALLET_SUCCESS";
export const FETCH_WALLET_FAILURE = "FETCH_WALLET_FAILURE";

export const FETCH_ORGANISATION_USERS_REQUEST = "FETCH_ORGANISATION_USERS_REQUEST";
export const FETCH_ORGANISATION_USERS_SUCCESS = "FETCH_ORGANISATION_USERS_SUCCESS";
export const FETCH_ORGANISATION_USERS_FAILURE = "FETCH_ORGANISATION_USERS_FAILURE";

export const FETCH_ORGANISATION_WALLET_ENTRIES_REQUEST = "FETCH_ORGANISATION_WALLET_ENTRIES_REQUEST";
export const FETCH_ORGANISATION_WALLET_ENTRIES_SUCCESS = "FETCH_ORGANISATION_WALLET_ENTRIES_SUCCESS";
export const FETCH_ORGANISATION_WALLET_ENTRIES_FAILURE = "FETCH_ORGANISATION_WALLET_ENTRIES_FAILURE";

export const FETCH_ORGANISATION_USER_ENTRIES_REQUEST = "FETCH_ORGANISATION_USER_ENTRIES_REQUEST";
export const FETCH_ORGANISATION_USER_ENTRIES_SUCCESS = "FETCH_ORGANISATION_USER_ENTRIES_SUCCESS";
export const FETCH_ORGANISATION_USER_ENTRIES_FAILURE = "FETCH_ORGANISATION_USER_ENTRIES_FAILURE";


export const FETCH_SAAS_RECORDS_REQUEST = "FETCH_SAAS_RECORDS_REQUEST";
export const FETCH_SAAS_RECORDS_SUCCESS = "FETCH_SAAS_RECORDS_SUCCESS";
export const FETCH_SAAS_RECORDS_FAILURE = "FETCH_SAAS_RECORDS_FAILURE";

export const FETCH_SASS_ENTRIES_REQUEST = "FETCH_SASS_ENTRIES_REQUEST";
export const FETCH_SASS_ENTRIES_SUCCESS = "FETCH_SASS_ENTRIES_SUCCESS";
export const FETCH_SASS_ENTRIES_FAILURE = "FETCH_SASS_ENTRIES_FAILURE";

export const FETCH_WITHDRAWAL_ENTRIES_REQUEST = "FETCH_WITHDRAWAL_ENTRIES_REQUEST";
export const FETCH_WITHDRAWAL_ENTRIES_SUCCESS = "FETCH_WITHDRAWAL_ENTRIES_SUCCESS";
export const FETCH_WITHDRAWAL_ENTRIES_FAILURE = "FETCH_WITHDRAWAL_ENTRIES_FAILURE";

export const FETCH_DELIVERY_COLLECTIONS_REQUEST = "FETCH_DELIVERY_COLLECTIONS_REQUEST";
export const FETCH_DELIVERY_COLLECTIONS_SUCCESS = "FETCH_DELIVERY_COLLECTIONS_SUCCESS";
export const FETCH_DELIVERY_COLLECTIONS_FAILURE = "FETCH_DELIVERY_COLLECTIONS_FAILURE";

export const FETCH_ADVANCED_PAYMENTS_REQUEST = "FETCH_ADVANCED_PAYMENTS_REQUEST";
export const FETCH_ADVANCED_PAYMENTS_SUCCESS = "FETCH_ADVANCED_PAYMENTS_SUCCESS";
export const FETCH_ADVANCED_PAYMENTS_FAILURE = "FETCH_ADVANCED_PAYMENTS_FAILURE";

export const FETCH_ACTIVITY_LOG_REQUEST = "FETCH_ACTIVITY_LOG_REQUEST";
export const FETCH_ACTIVITY_LOG_SUCCESS = "FETCH_ACTIVITY_LOG_SUCCESS";
export const FETCH_ACTIVITY_LOG_FAILURE = "FETCH_ACTIVITY_LOG_FAILURE";
