export const FETCH_VEHICLE_REQUEST = "FETCH_VEHICLE_REQUEST";
export const FETCH_VEHICLE_SUCCESS = "FETCH_VEHICLE_SUCCESS";
export const FETCH_VEHICLE_FAILURE = "FETCH_VEHICLE_FAILURE";

export const FETCH_FLEET_REQUEST = "FETCH_FLEET_REQUEST";
export const FETCH_FLEET_SUCCESS = "FETCH_FLEET_SUCCESS";
export const FETCH_FLEET_FAILURE = "FETCH_FLEET_FAILURE";

export const FETCH_FLEET_ORGANISATION_PROFILE_REQUEST = "FETCH_FLEET_ORGANISATION_PROFILE_REQUEST";
export const FETCH_FLEET_ORGANISATION_PROFILE_SUCCESS = "FETCH_FLEET_ORGANISATION_PROFILE_SUCCESS";
export const FETCH_FLEET_ORGANISATION_PROFILE_FAILURE = "FETCH_FLEET_ORGANISATION_PROFILE_FAILURE";

export const FETCH_FLEET_CATEGORIES_REQUEST = "FETCH_FLEET_CATEGORIES_REQUEST";
export const FETCH_FLEET_CATEGORIES_SUCCESS = "FETCH_FLEET_CATEGORIES_SUCCESS";
export const FETCH_FLEET_CATEGORIES_FAILURE = "FETCH_FLEET_CATEGORIES_FAILURE";

export const FETCH_CATEGORIES_CHARGE_REQUEST = "FETCH_CATEGORIES_CHARGE_REQUEST";
export const FETCH_CATEGORIES_CHARGE_SUCCESS = "FETCH_CATEGORIES_CHARGE_SUCCESS";
export const FETCH_CATEGORIES_CHARGE_FAILURE = "FETCH_CATEGORIES_CHARGE_FAILURE";
