import {useEffect, useState} from "react";
import {createRoot} from 'react-dom/client';
import {Provider} from "react-redux";

import App from './App';
import store from "./store";
import reportWebVitals from './reportWebVitals';
import {GoogleOAuthProvider} from "@react-oauth/google";
import {GeneralAppInfo} from "./utils/helpers/constants";
import NetworkModal from "./components/modals/NetworkModal";
import {getStoreOnReload} from "./store/actions/auth.action";

import './index.css';
import "./assets/scss/style.scss";
import "./assets/scss/icons.scss";
import "./assets/scss/color.scss";
import "./assets/scss/extra.scss";


getStoreOnReload(store);

const AppRoot = () => {
  const [networkStatus, updateNetworkStatus] = useState(navigator.onLine);
  const updateNetwork = () => updateNetworkStatus(navigator.onLine);
  useEffect(() => {
    window.addEventListener("offline", updateNetwork);
    window.addEventListener("online", updateNetwork);
    return () => {
      window.removeEventListener("offline", updateNetwork);
      window.removeEventListener("online", updateNetwork);
    };
  }, []);

  const networkRender = !(networkStatus) ? <NetworkModal/> : null

  return (
    <Provider store={store}>
      <GoogleOAuthProvider clientId={GeneralAppInfo.GOOGLE_AUTH_KEY}>
        <App/>
        {networkRender}
      </GoogleOAuthProvider>
    </Provider>
  )
}


const root = createRoot(document.getElementById('root'));
root.render(<AppRoot/>);


reportWebVitals();
