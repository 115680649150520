import * as Constants from "../constants/configs.constants";
import {Loading, ServiceType} from "../../utils/helpers/constants";
import {formatServices, formatSupportedCountries} from "../../utils/helpers/logicHelper";


const initialState = {
  supportedCountries: [],
  supportedCountry: {},
  services: [],
  service: {},
  enabledServices: {},
  countryServices: [],
  insuranceProducts: [],
  organisationServices: [],
  recommendedService: [],
  integrationKeys: [],
  webhookEvents: [],
  loadingSupportedCountries: "",
  loadingSupportedCountry: "",
  loadingCountryProducts: "",
  loadingServices: "",
  loadingAService: "",
  loadingCountryServices: "",
  loadingOrganisationServices: "",
  loadingRecommendedService: "",
  loadingIntegrationKeys: "",
  loadingWebhookEvents: "",
};

const formatServiceModule = supportedServices => {
  let services = {};
  services.USR = supportedServices.some(each => each['service_ref'] === ServiceType.USR);
  services.BOK = supportedServices.some(each => each['service_ref'] === ServiceType.BOK);
  services.TRP = supportedServices.some(each => each['service_ref'] === ServiceType.TRP);
  services.FLT = supportedServices.some(each => each['service_ref'] === ServiceType.FLT);
  services.DRM = supportedServices.some(each => each['service_ref'] === ServiceType.DRM);
  services.IMS = supportedServices.some(each => each['service_ref'] === ServiceType.IMS);
  services.REC = supportedServices.some(each => each['service_ref'] === ServiceType.REC);
  services.WHA = supportedServices.some(each => each['service_ref'] === ServiceType.WHA);
  return services;
};

const configsReducer = (state = initialState, action) => {
  switch (action.type) {

    case Constants.FETCH_SUPPORTED_COUNTRIES_REQUEST:
      return {...state, loadingSupportedCountries: Loading.FETCHING};

    case Constants.FETCH_SUPPORTED_COUNTRIES_SUCCESS:
      let supportedCountries = formatSupportedCountries(action.payload);
      return {...state, loadingSupportedCountries: Loading.SUCCESS, supportedCountries};

    case Constants.FETCH_SUPPORTED_COUNTRIES_FAILURE:
      return {...state, loadingSupportedCountries: Loading.ERROR};

    case Constants.FETCH_SUPPORTED_COUNTRY_REQUEST:
      return {...state, loadingSupportedCountry: Loading.FETCHING};

    case Constants.FETCH_SUPPORTED_COUNTRY_SUCCESS:
      return {...state, loadingSupportedCountry: Loading.SUCCESS, supportedCountry: action.payload};

    case Constants.FETCH_SUPPORTED_COUNTRY_FAILURE:
      return {...state, loadingSupportedCountry: Loading.ERROR};

    case Constants.FETCH_COUNTRY_SERVICES_REQUEST:
      return {...state, loadingCountryServices: Loading.FETCHING};

    case Constants.FETCH_COUNTRY_SERVICES_SUCCESS:
      return {...state, loadingCountryServices: Loading.SUCCESS, countryServices: action.payload};

    case Constants.FETCH_COUNTRY_SERVICES_FAILURE:
      return {...state, loadingCountryServices: Loading.ERROR};

    case Constants.FETCH_INSURANCE_TYPES_REQUEST:
      return {...state, loadingCountryProducts: Loading.FETCHING};

    case Constants.FETCH_INSURANCE_TYPES_SUCCESS:
      return {...state, loadingCountryProducts: Loading.SUCCESS, insuranceProducts: action.payload};

    case Constants.FETCH_INSURANCE_TYPES_FAILURE:
      return {...state, loadingCountryProducts: Loading.ERROR};

    case Constants.FETCH_ORGANISATION_SERVICES_REQUEST:
      return {...state, loadingOrganisationServices: Loading.FETCHING};

    case Constants.FETCH_ORGANISATION_SERVICES_SUCCESS:
      const enabledServices = formatServiceModule(action.payload);
      return {
        ...state,
        enabledServices,
        loadingOrganisationServices: Loading.SUCCESS,
        organisationServices: action.payload
      };

    case Constants.FETCH_ORGANISATION_SERVICES_FAILURE:
      return {...state, loadingOrganisationServices: Loading.ERROR};

    case Constants.FETCH_ALL_SERVICE_REQUEST:
      return {...state, loadingServices: Loading.FETCHING};

    case Constants.FETCH_ALL_SERVICE_SUCCESS:
      let services = formatServices(action.payload.data);
      return {...state, loadingServices: Loading.SUCCESS, services, count: action.payload.count};

    case Constants.FETCH_ALL_SERVICE_FAILURE:
      return {...state, loadingServices: Loading.ERROR};

    case Constants.FETCH_SERVICE_REQUEST:
      return {...state, loadingAService: Loading.FETCHING};

    case Constants.FETCH_SERVICE_SUCCESS:
      return {...state, loadingAService: Loading.SUCCESS, service: action.payload};

    case Constants.FETCH_SERVICE_FAILURE:
      return {...state, loadingAService: Loading.ERROR};

    case Constants.FETCH_RECOMMENDED_SERVICES_REQUEST:
      return {...state, loadingRecommendedService: Loading.FETCHING};

    case Constants.FETCH_RECOMMENDED_SERVICES_SUCCESS:
      return {...state, loadingRecommendedService: Loading.SUCCESS, recommendedService: action.payload};

    case Constants.FETCH_RECOMMENDED_SERVICES_FAILURE:
      return {...state, loadingRecommendedService: Loading.ERROR};

      case Constants.FETCH_INTEGRATION_KEYS_REQUEST:
      return {...state, loadingIntegrationKeys: Loading.FETCHING};

    case Constants.FETCH_INTEGRATION_KEYS_SUCCESS:
      return {...state, loadingIntegrationKeys: Loading.SUCCESS, integrationKeys: action.payload};

    case Constants.FETCH_INTEGRATION_KEYS_FAILURE:
      return {...state, loadingIntegrationKeys: Loading.ERROR};

    case Constants.FETCH_WEBHOOK_EVENTS_REQUEST:
      return { ...state, loadingWebhookEvents: Loading.FETCHING };

    case Constants.FETCH_WEBHOOK_EVENTS_SUCCESS:
      return { ...state, loadingWebhookEvents: Loading.SUCCESS, webhookEvents: action.payload };

    case Constants.FETCH_WEBHOOK_EVENTS_FAILURE:
      return { ...state, loadingWebhookEvents: Loading.ERROR };

      case 'RESET_STATE':
      return initialState;

    default:
      return state;
  }
}

export default configsReducer;
