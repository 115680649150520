import {combineReducers} from "redux";

import analyticsReducer from "./analytics.reducer";
import authReducer from "./auth.reducer";
import configsReducer from "./configs.reducer";
import bookingsReducer from "./booking.reducer";
import componentReducer from "./component.reducer";
import fleetReducer from "./fleet.reducer";
import profileReducer from "./profile.reducer";
import subscriptionReducer from "./subscription.reducer";
import usersReducer from "./users.reducer";
import financeReducers from "./fianace.reducer";

const rootReducer = combineReducers({
  analyticsReducer,
  authReducer,
  bookingsReducer,
  componentReducer,
  configsReducer,
  fleetReducer,
  profileReducer,
  subscriptionReducer,
  usersReducer,
  financeReducers
});

export default rootReducer;
