import { Fragment, useEffect, useState } from "react";
import { Container } from "react-bootstrap";

const ErrorBoundaryHook = ({ children }) => {
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    const handleError = () => setHasError(true);

    window.addEventListener('error', handleError);
    window.addEventListener('unhandledrejection', handleError);

    return () => {
      window.removeEventListener('error', handleError);
      window.removeEventListener('unhandledrejection', handleError);
    };
  }, []);

  return (
    <Fragment>
      {hasError
        ? <Container className="d-flex flex-column justify-content-center align-items-center vh-100">
            <h3>We have no clue what you’re looking for. Let’s help you navigate to a familiar page.</h3>
            <p className="my-2">Kindly Refersh or click the button below</p>
            <button className="btn btn-primary mt-3" onClick={() => window.location.reload()}>Refresh</button>
        </Container>
        : children
      }
    </Fragment>
  );
}

export default ErrorBoundaryHook;