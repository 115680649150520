import {dodgeInstance as Axios} from './axios.service';

const ConfigsService = {
  updateNotificationMatrices(payload) {
    return Axios({
      method: 'PUT',
      url: `locale-service/messaging/tenant/notification-matrix`,
      data: payload
    });
  },

  createRole(payload) {
    return Axios({
      method: 'POST',
      url: `locale-service/configs/roles/create`,
      data: payload
    });
  },

  getRoles(filter) {
    return Axios({
      method: 'GET',
      url: `locale-service/configs/roles/${filter}`
    });
  },

  updateRole(id, payload) {
    return Axios({
      method: 'PATCH',
      url: `locale-service/configs/roles/update/${id}`,
      data: payload
    });
  },

  deleteRole(id) {
    return Axios({
      method: 'DELETE',
      url: `locale-service/configs/roles/delete/${id}`,
    });
  },

  updateRolePermission(payload) {
    return Axios({
      method: 'PATCH',
      url: `locale-service/configs/roles/update-permission`,
      data: payload
    });
  },

  getPermissions() {
    return Axios({
      method: 'GET',
      url: `locale-service/configs/permissions`
    });
  },

  getTenantPermissions() {
    return Axios({
      method: 'GET',
      url: `locale-service/tenants/user-permissions`
    });
  },

  getSupportedCountries(filter) {
    return Axios({
      method: 'GET',
      url: `locale-service/configs/countries/${filter}`,
    });
  },

  getCountryServices(filter) {
    return Axios({
      method: 'GET',
      url: `locale-service/configs/services/country/${filter}`,
    });
  },

  getInsuranceProducts(country) {
    return Axios({
      method: 'GET',
      url: `locale-service/configs/insurance/${country}`
    });
  },

  getOrganisationServices(filter) {
    return Axios({
      method: 'GET',
      url: `locale-service/configs/services/organisation/${filter}`,
    });
  },

  getAllService() {
    return Axios({
      method: 'GET',
      url: `locale-service/configs/services`,
    });
  },

  getService(ref) {
    return Axios({
      method: 'GET',
      url: `locale-service/configs/services/${ref}`,
    });
  },

  getRecommendedServices(ref) {
    return Axios({
      method: 'GET',
      url: `locale-service/configs/services/organisation/recommended-services/${ref}`,
    });
  },

  /********************* API KEYS AND WEBHOOKS **************************/
  getIntegrationKeys() {
    return Axios({
      method: 'GET',
      url: `locale-service/configs/integration/retrieve-keys`,
    });
  },

  generatePublicKey() {
    return Axios({
      method: 'GET',
      url: `locale-service/configs/integration/generate-keys`,
    });
  },

  addWebhookURL(payload) {
    return Axios({
      method: 'POST',
      url: `locale-service/configs/integration/webhook-url`,
      data: payload
    });
  },
  getWebhookEvents() {
    return Axios({
      method: 'GET',
      url: `locale-service/configs/integration/get-webhook-events`,
    });
  },
  updateOrgWebhookEvents(payload) {
    return Axios({
      method: 'PUT',
      url: `locale-service/configs/integration/update-webhook-event`,
      data: payload
    });
  },

};

export default ConfigsService;
