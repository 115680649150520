import * as AuthConstants from "../constants/auth.constants";
import {Loading} from "../../utils/helpers/constants";

const initialState = {
  isAuthenticated: false,
  userId: "",
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case AuthConstants.LOGIN_REQUEST:
      return {...state, loginStatus: Loading.FETCHING};
    
    case AuthConstants.LOGIN_SUCCESS:
      return {...state, userId: action.payload.id};
    
    case AuthConstants.LOGIN_FAILURE:
      return {...state, loginStatus: Loading.ERROR};
    
    case AuthConstants.SET_LOGGED_IN_USER:
      return {...state, isAuthenticated: true};
  
  
    case 'RESET_STATE':
      return initialState;
      
    default:
      return state;
  }
};

export default authReducer;
